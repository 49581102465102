.desktop {
	display: none;
}

.mobile {
	display: block;
}

.app {
	section.fallback {
		display: block;
	}		
}