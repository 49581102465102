.tablet {
	display: block;
}

.app {
	.scroll-bar {
		position: absolute;
		top: 0px;
		right: 0px;
		width: 8px;
		height: 100%;

		.scrub {
			width: 5px;
			height: 40px;
			margin-left: 2px;
			border-radius: 20px;
		}
	}

	section.share {
		display: none;
	}

	section.menu {
		.bottom {

			.container {
				margin-left: 0px;
				width: 440px;

				.button {
					width: 160px;
				}
			}
		}
	}

	section.home {
		.hashtag {
			left: 35px;
		}

		.hashtag-full {
			left: 35px;
		}
	}

	section.welcome {

		.magic-words {
			top: 35%;

			.logo {
				video {
					display: none;
				}

				img {
					width: 600px;
					opacity: 1;
				}
			}

			.description {
				font-size: 13px;
				letter-spacing: 17.2px;
				margin-top: -70px;
			}

			&.en {
				.description {
					letter-spacing: 22.5px;
				}
			}
		}
	}

	section.introduction {

		.video {

			.top {
				height: 70px;
			}
		}

		.google {
			width: 90px;
			margin-bottom: 25px;
		}

		.text {
			top: 45%;

			.title {
				letter-spacing: 6px;
				line-height: 55px;
				margin: 0 auto;
				margin-top: 70px;

				p {
					font-size: 40px;

					span {
						height: 45px;

						&:nth-child(1) {
							font-size: 34px;
							position: absolute;
							top: 0px;
							left: 50%;
							overflow: hidden;
						}
						&:nth-child(2) {
							font-size: 34px;
							position: absolute;
							top: 45px;
							left: 50%;
							text-align: left;
						}
					}
				}
			}

			.description {
				margin-top: 20px;
				line-height: 18px;
				letter-spacing: 1px;
				transform: translate3d(0%, -30%, 0);
				opacity: 0;

				p {
					width: 70%;
					margin: 0 auto;
					font-size: 15px;

					span {
						display: inline;
						opacity: 1;
					}
				}
			}
		}

		.scroll {
			.title {
				width: 185px;
				margin-bottom: 10px;
			}
		}
	}

	section.documentary {

		.graph {

			&.graph-1 {
				display: block;
			}

			&.graph-2 {
				right: 5%;
			}

			&.graph-3 {
				right: 20%;
			}
		}

		.chromeless {
			top: 20%;
			left: 50%;
			width: 95%;
			height: 500px;
			transform: translate3d(-50%, 0%, 0px);

			.controls {
				pointer-events: none;

				.button {
					width: 100px;
					height: 100px;
				}

				.title {
					width: 150px;
					margin-top: 30px;

					p {
						font-size: 10px;
					}

					span {
						font-size: 12px;
					}

				}
			}
		}
		
		.text {
			top: 65%;
			left: 11%;
			transform: translate3d(0px, -10%, 0px);

			.highlight {
				width: 200px;

				p {
					font-size: 10px;
					letter-spacing: 7px;
				}
			}

			.title {
				margin-top: 35px;

				p {
					font-size: 50px;
					letter-spacing: 2px;
					line-height: 50px;
				}
			}

			.line-horizontal {
				width: 150px;

				&.en {
					width: 130px;
				}
			}
		}
	}

	section.project {
		.background {
			.cover-text {

				.container {

					.title {
						margin-bottom: 20px;

						.text-underline {
							p {
								font-size: 11px;
								letter-spacing: 7px;
							}
						}
					}

					.description {
						width: 100%;
						float: none;

						p {
							width: 95%;
							font-size: 12px;
							line-height: 20px;
						}
					}

				}
			}

			.book {
				width: 303.5px;
				height: 363px;
			}
		}

		.text {
			left: 8%;

			.highlight {
				width: 200px;

				p {
					font-size: 10px;
					letter-spacing: 7px;
				}
			}

			.title {
				margin-top: 35px;
				height: 100px;

				p {
					font-size: 50px;
					letter-spacing: 2px;
					line-height: 50px;
				}
			}

			.line-horizontal {
				width: 60px;

				&.en {
					width: 85px;
				}
			}
		}

		.list {
			left: 8%;

			ul {
				list-style: none;

				li {
					height: 40px;
					width: 300px;

					.bullet {
						top: 1px;
						left: 1px;
						width: 5px;
						height: 5px;
					}

					.icon {
						top: -7px;
						left: -7px;
						width: 20px;
						height: 20px;
					}

					.line {
						height: 40px;
					}

					.title {
						font-size: 10px;
						letter-spacing: 6px;
					}
				}
			}
		}
	}

	section.technology {
		.step-right {

			.text {
				right: 8%;

				.link {
					margin-top: 40px;
					height: 40px;

					.text {
						p {
							padding-left: 15px;
							padding-right: 15px;
						}
					}
				}

				.details {
					height: 150px;
					margin-top: 40px;

					p {
						width: 180px;
					}
				}

				.download {
					margin-top: 30px;
					margin-bottom: 10px;

					p {
						font-size: 10px;
						letter-spacing: 6px;
					}
				}

				.stores {
					a {
						img {
							height: 30px;
						}
					}
				}

				.line-horizontal {
					width: 160px;

					&.en {
						width: 250px;
					}
				}
			}
		}

		.step-left {
			.text {
				right: 8%;

				.highlight {
					width: auto;

					p {
						font-size: 10px;
						letter-spacing: 7px;
					}
				}

				.title {
					margin-top: 35px;
					height: 150px;

					p {
						font-size: 50px;
						letter-spacing: 2px;
						line-height: 50px;
					}
				}

				.line-horizontal {
					width: 60px;

					&.en {
						width: 140px;
					}
				}

				.details {
					margin-top: 30px;
					height: 250px;

					p {
						font-size: 11px;
						line-height: 20px;
						width: 200px;
					}
				}

				.link {
					margin-top: 30px;
					margin-bottom: 0px;
					height: 40px;

					.icon {
						width: 30px;
						height: 30px;
					}

					.text {
						height: 30px;

						p {
							font-size: 11px;
							line-height: 30px;
						}
					}
				}

				.download {
					margin-top: 30px;
					margin-bottom: 10px;

					p {
						width: 250px;
						font-size: 10px;
						letter-spacing: 8px;

						span {
							direction: inline;
						}
					}
				}

				.stores {
					a {
						img {
							height: 30px;
						}
					}
				}
			}

			.legal {
				width: 250px;
				p {
					font-size: 8px;

					span {
						display: inline;
					}
				}
			}
		}
	}

	section.writers {
		.text {
			left: 8%;

			.highlight {
				width: 200px;

				p {
					font-size: 10px;
					letter-spacing: 7px;
				}
			}

			.title {
				margin-top: 35px;
				height: 100px;

				p {
					font-size: 50px;
					letter-spacing: 2px;
					line-height: 50px;
				}
			}

			.line-horizontal {
				width: 88px;

				&.en {
					width: 92px;
				}
			}

			.details {
				margin-top: 30px;

				p {
					font-size: 11px;
					line-height: 20px;
					width: 180px;
				}
			}
		}
	}

	section.instagram {
		.text {
			left: 8%;

			.highlight {
				width: 200px;

				p {
					font-size: 10px;
					letter-spacing: 7px;
				}
			}

			.title {
				margin-top: 35px;
				height: 100px;

				p {
					font-size: 50px;
					letter-spacing: 2px;
					line-height: 50px;
				}
			}

			.line-horizontal {
				width: 130px;

				&.en {
					width: 130px;
				}
			}

			.details {
				margin-top: 30px;

				p {
					font-size: 11px;
					line-height: 20px;
					width: 300px;
				}
			}
		}
	}

	section.footer {
		.container {
			height: 100%;

			.content {
				height: 750px;

				.magic-words {
					// top: 30px;

					.description {
						font-size: 13px;
						letter-spacing: 5px;
					}

					.logo {
						img {
							opacity: 1;
						}
					}
				}

				.details {
					// top: 320px;
					font-size: 14px;

					p {
						width: 100%;
					}
				}

				.follow {
					// top: 420px;
				}

				.download {
					top: 550px;
				}

			}
	
		}
	}

	section.modal-writers {
		.content {
			width: calc(100% - 500px);
		}
	}
}