
#awwwards{
	top: 50px;
}
#awwwards a{
	width: 50px;
	height: 80px;
	background-size: 100% auto;
}
.desktop {
	display: none;
}

.mobile {
	display: block;
}

html,
body{
	overflow: hidden;
	position: fixed;
}
.app {
	overflow: hidden;
	height: 100vh;

	.scroll-bar {
		position: absolute;
		top: 0px;
		right: 0px;
		width: 8px;
		height: 100%;

		.scrub {
			width: 5px;
			height: 60px;
			margin-left: 2px;
			border-radius: 20px;
		}
	}

	section.navigation {
		height: 45px;

		.menu {
			height: 30px;

			.left {
				margin-left: 10px;

				.logo {

					img {
						height: 30px;
					}
				}

				.title {
					padding-left: 10px;
					font-size: 12px;

					.text {
						line-height: 30px;
					}

					.cursor {
						width: 1px;
						height: 15px;
						margin-top: 7px;
					}
				}
			}

			.right {
				margin-right: 10px;
			}
		}
	}

	section.share {
		display: none;
	}

	section.menu {
		// left: 0%;
		width: 100%;

		.top {
			height: 45px;

			.container {
				height: 30px;

				.button {
					margin-right: 10px;
				}
			}

		}

		.content {
			position: absolute;
			top: 50%;
			left: 50%;
			width: 80%;
			height: auto;
			transform: translate3d(-50%, -210px, 0);

			.wrap-list {

				.list-item {
					height: 30px;

					.title {
						position: relative;

						p {
							line-height: 30px;
							letter-spacing: 2px;
						}
					}

					.bar {
						display: none;
					}

					&.active {
						.title {
							p {
								color: #ffffff;
							}
						}
					}
				}
			}
		}

		.bottom {
			position: absolute;
			bottom: 0%;
			height: 35%;
			width: 100%;
			float: left;

			.container {
				position: absolute;
				// height: 160px;
				height: 120px;
				width: 215px;
				left: 50%;
				top: 50%;
				transform: translate3d(-50%, -50%, 0);
				margin-left: 0px;

				.title {
					position: relative;
					height: 50px;

					p {
						height: 50px;
						line-height: 50px;
						letter-spacing: 2px;
					}
				}

				.stores {
					height: 50px;
				}
			}

		}
	}

	div.menu-overlay {
		display: none;
	}

	section.home {
		.hashtag {
			display: none;
		}

		.hashtag-full {
			display: none;
		}

		.bars {

			.bar {
				width: 5px;
			}

			.top {
				width: 100%;
				display: block;
			}

			.bottom {
				width: 100%;
				display: block;
				height: 10px;
				top: 100%;
				margin-top: -10px;
			}

		}
	}

	section.welcome {

		.brand {
			top: 50%;
			width: 280px;
			margin-left: -139px;
			margin-top: -20px;

			.logo {
				img {
					width: 110px;
					margin-left: -10px;
				}
			}

			.title {
				margin-top: 15px;

				.text-underline {

					.content {
						width: 280px;
					}

					p {
						font-size: 11px;
						letter-spacing: 3.4px;
					}

				}

			}

			&.en {
				.title {
					.text-underline {
						p {
							letter-spacing: 4.5px;
						}
					}
				}
			}
		}

		.magic-words {
			position: absolute;
			width: 100%;
			top: 50%;
			height: 180px;
			margin-top: -90px;

			.logo {
				width: 100%;
				text-align: center;
				margin-bottom: 0px;

				video {
					display: none;
				}

				img {
					width: 270px;
					margin: 0 auto;
					opacity: 1;
				}
			}

			.description {
				font-size: 12px;
				letter-spacing: 4px;
				font-weight: bold;
				margin-top: 0px;
				width: 278px;
				text-align: center;
				margin: 0 auto;
				padding-left: 0px;

				p {
					position: relative;
				}
			}

			&.en {
				.description {
					letter-spacing: 6px;
				}
			}
		}
	}

	section.introduction {
		position: relative;
		// opacity: 0.5;

		.google {
			display: none;
		}

		.video {

			.player {
				position: relative;
				top: 0px;
				left: 0px;
				width: calc(100%);
				height: calc(100%);
			}

			.top {
				height: 55px;
			}
		}

		.text {
			top: 40%;

			.highlight {
				width: 100px;
				margin-left: -50px;
				margin-top: -10px;

				.content {
					p {
						font-size: 12px;
						line-height: 12px;
					}
				}

				.cursor {
					height: 12px;
					margin-left: 7px;
				}
			}

			.title {
				letter-spacing: 2px;
				line-height: 27px;
				width: 240px;
				margin: 0 auto;
				margin-top: 27px;
				height: 130px;

				&.en {
					width: 270px;
				}

				p {
					font-size: 27px;
					color: #ffffff;
					height: 125px;

					span {
						display: inline;

						&:nth-child(1) {
							position: relative;
							top: 0px;
							left: 0px;
							width: auto;
							margin-left: 0px;
							white-space: initial;
						}
						&:nth-child(2) {
							position: relative;
							top: 0px;
							left: 0px;
							width: auto;
							margin-left: 0px;
							white-space: initial;
						}
					}
				}


			}

			.description {
				margin-top: 0px;
				line-height: 20px;
				letter-spacing: 1px;
				opacity: 0;
				transform: translate3d(0%, -10%, 0);

				p {
					font-size: 11px;
					width: 270px;
					margin: 0 auto;

					span {
						display: inline;
						opacity: 1;
					}
				}
			}
		}

		.scroll {
			bottom: 3%;
			width: 100%;

			.title {
				width: 185px;
				margin-bottom: 0px;
			}

			.button {
				transform: scale(0.7);

				canvas {
					width: 60px;
					height: 60px;
				}

				img {
					transform: scale(0.5);
				}
			}
		}
	}

	section.documentary {
		.play-button-overlay{
			position: absolute;
			z-index: 99999999;
			width: 98%;
			height: 33%;
			top: 50%;
			left: 50%;
			transform: translate3d(-50%,calc(-50% - 90px),0);
			cursor: pointer;
		}
		.graph {

			&.graph-1 {
				display: none;
			}

			&.graph-2 {
				right: 5%;
			}

			&.graph-3 {
				right: 20%;
			}
		}

		.chromeless {
			top: 20%;
			left: 50%;
			width: 95%;
			height: 200px;
			transform: translate3d(-50%, 0%, 0px);
			.video-player-movie{

				iframe {
					opacity: 1;
					position: relative;
					top: 0px;
					left: 0%;
					width: 100%;
					height: 100%;
					transform: translate3d(0%, 0%, 0);
					pointer-events: auto;
					opacity: 0;
				}
			}
			.controls {
				margin-top: -30px;
				pointer-events: none;

				.button {
					width: 70px;
					height: 70px;

					canvas {
						width: 70px;
						height: 70px;
					}
				}

				.title {
					display: none;
				}
			}
		}

		.text {
			position: absolute;
			top: 50%;
			left: 11%;
			transform: translate3d(0px, -10%, 0px);

			.highlight {

				p {
					font-size: 10px;
					letter-spacing: 7px;
				}
			}

			.title {
				height: 120px;
				margin-top: 30px;

				p {
					font-size: 40px;
					letter-spacing: 2px;
					line-height: 40px;
				}
			}

			.line-horizontal {
				width: 105px;
				margin-top: 15px;
				height: 7px;

				&.en {
					width: 90px;
				}
			}
		}
	}

	section.project {

		.background {

			.cover-text {
				z-index: 10;
				left: 50%;
				width: 100%;
				height: 60%;
				transform: translate3d(-50%, -10%, 0px);

				.container {
					top: 40%;
					width: 79%;
					left: 50%;
					transform: translate3d(-50%, -50%, 0px);

					.title {
						width: 100%;
						height: 22px;
						margin-bottom: 15px;

						.text-underline {
							p {
								font-size: 10px;
								letter-spacing: 6px;
							}
						}
					}

					.description {
						width: 100%;
						height: 150px;
						margin-top: 22px;

						p {
							width: 100%;
							min-width: 10px;
							text-align: left;
							font-size: 11px;
							line-height: 13px;
						}
					}

				}
			}

			.cover-image {
				z-index: 1;

				.container-1, .container-3, .container-5, .container-7 {
					display: none;
				}

				.container-2, .container-4, .container-6, .container-8 {
					position: absolute;
					top: 0%;
					left: 0%;
					width: 100%;
					height: 50%;
					background-color: #000000;
				}
			}

			.book {
				display: none;
			}
		}

		.text {
			position: absolute;
			top: 10%;
			left: 11%;
			transform: translate3d(0px, 0%, 0px);

			.highlight {

				p {
					font-size: 10px;
					letter-spacing: 7px;
				}
			}

			.title {
				margin-top: 30px;
				height: 80px;

				p {
					font-size: 40px;
					letter-spacing: 2px;
					line-height: 40px;
				}
			}

			.line-horizontal {
				width: 50px;
				margin-top: 15px;
				height: 7px;

				&.en {
					width: 67px;
				}
			}
		}

		.list {
			top: 100%;
			left: 50%;
			margin-top: -35px;
			width: 165px;
			transform: translate3d(-50%, 0%, 0);
			z-index: 10;
			margin-left: 10px;

			ul {
				list-style: none;

				li {
					position: relative;
					height: 25px;
					width: 70px;
					float: left;
					margin: 0 auto;

					.bullet {
						background-color: #000000;
					}

					.icon {
						left: -6.5px;
						top: -6px;
					}

					.line {
						top: 3px;
						left: 3px;
						width: 70px;
						height: 1px;
						opacity: 0.2;
						background-color: transparent;

						.line-background {
							background-color: #000000;
							opacity: 0.2;
						}
					}

					.title {
						display: none;
					}

					&:last-child {
						width: 25px;

						.line {
							display: none;
						}
					}
				}
			}
		}

		.hashtag {
			left: 96%;
			top: 25%;
		}
	}

	section.technology {
		&.technology-1{
			height: 120%;
			transform: translate3d(0,85%,0);
		}
		&.technology-2{
			height: 100%;
			transform: translate3d(0,120%,0);
		}
		.step-right {

			.google {
				display: none;
			}

			.background-white {
				display: none;
			}

			.background {
				top: 0%;
				left: 0px;
				width: 100%;
				height: 100%;
				pointer-events: none;

				.graph {
					display: none;
				}

				.cover-text {
					top: 50%;
					left: 0%;
					width: 100%;
					height: 50%;

					.container {
						top: 10%;
						width: 100%;
						transform: translate3d(0px, 0%, 0px);

						.description {
							width: 100%;

							p {
								width: 79%;
								min-width: 10px;
								text-align: left;
								font-size: 11px;
								line-height: 17px;
							}
						}
					}

					.link {
						position: absolute;
						left: 10%;
						bottom: 10%;
						pointer-events: auto;
					}

					.download {
						pointer-events: auto;
					}

					.stores {
						pointer-events: auto;
					}
				}

				.cover-image {

					.container {
						top: 0px;
						left: 0px;
						width: 100%;
						height: 50%;

						.background {
							top: 0px;
							left: 0px;
							width: 100%;
							height: 100%;
							background-image: url('../assets/images/website/home/technology/background_2_mobile.jpg');
						}
					}
				}
			}

			.bars {
				display: none;
			}

			.text {
				position: absolute;
				top: 14%;
				left: 11%;
				transform: translate3d(0, 0%, 0);
				z-index: 10;

				.highlight {
					float: left;

					p {
						font-size: 10px;
						letter-spacing: 7px;
					}
				}

				.title {
					float: left;
					margin-top: 0px;
					height: 120px;

					p {
						float: left;
						font-size: 40px;
						letter-spacing: 2px;
						line-height: 40px;
						color: #ffffff;
						text-align: left;
					}
				}

				.line-horizontal {
					float: left;
					width: 130px;
					margin-top: 0px;
					height: 7px;

					&.en {
						width: 200px;
					}
				}

				.download {
					display: none;
				}
			}
		}

		.step-left {

			.background {
				top: 0px;
				left: 0px;
				width: 100%;
				height: 100%;
				z-index: 1;
				pointer-events: auto;

				&.image {
					pointer-events: none;
				}

				.graph {
					display: none;
				}

				.cover-text {
					top: 50%;
					left: 0%;
					width: 100%;
					height: 50%;

					.container {
						top: 10%;
						width: 100%;
						// overflow: scroll;
						// max-height: 40%;
						transform: translate3d(0px, 0%, 0px);
						pointer-events: none;

						.description {
							width: 100%;

							p {
								width: 79%;
								min-width: 10px;
								text-align: left;
								font-size: 11px;
								line-height: 17px;
							}
						}
					}

					.link {
						position: absolute;
						margin-top: 0px;
						height: 100px;
						left: 10%;
						bottom: 0%;

						.icon {
							width: 30px;
							height: 30px;
							margin-left: 0px;
						}

						.text {
							height: 30px;
							overflow: visible;

							p {
								line-height: 30px;
								font-size: 11px;
							}
						}
					}

					.legal {
						position: absolute;
						left: 10%;
						bottom: 5%;
						width: 250px;
						display: block;
					}
				}

				.cover-image {
					.container {
						top: 0px;
						left: 0px;
						width: 100%;
						height: 50%;

						.background {
							top: 0px;
							left: 0px;
							width: 100%;
							height: 100%;
							background-image: url('../assets/images/website/home/technology/background_1_mobile.jpg');
						}
					}
				}
			}

			.bars {
				display: none;
			}

			.text {
				position: absolute;
				top: 11%;
				left: 11%;
				transform: translate3d(0px, 0%, 0);
				z-index: 10;

				.highlight {
					float: left;
					height: 18px;

					p {
						font-size: 10px;
						letter-spacing: 7px;
					}
				}

				.title {
					float: left;
					margin-top: 30px;
					height: 120px;

					p {
						float: left;
						font-size: 40px;
						letter-spacing: 2px;
						line-height: 40px;
						color: #ffffff;
						text-align: left;
					}
				}

				.line-horizontal {
					float: left;
					width: 50px;
					margin-top: 15px;
					height: 7px;

					&.en {
						width: 115px;
					}
				}
			}
		}
	}

	section.writers {
		background-color: #ffffff;
		color: #000000;
		height: 100%;

		.step {
			position: absolute;
			width: 100%;
			height: 100%;

			&:last-child {
				background-color: #000000;
			}

			&.step-2, &.step-3, &.step-4, &.step-5, &.step-6, &.step-7, &.step-8, &.step-9, &.step-10 {
				display: none;
			}
		}

		.line {
			display: none;
		}

		.pictures {
			position: absolute;
			width: 100%;
			height: 100%;
			background-color: #000000;

			.picture {
				display: none;

				.line {
					display: none;
				}

				&.picture-1 {
					width: 100%;
					height: 100%;
					top: 0%;
					right: 0%;
					background-image: url('../assets/images/website/home/writers/picture_1.jpg');
					display: block;
				}
			}
		}

		.graphs {
			display: none;
		}

		.text {
			position: absolute;
			top: 14%;
			left: 11%;
			transform: translate3d(0px, 0%, 0px);

			.highlight {
				float: left;
				height: 18px;

				p {
					font-size: 10px;
					letter-spacing: 7px;
				}
			}

			.title {
				width: 100%;
				float: left;
				margin-top: 30px;

				p {
					float: left;
					font-size: 40px;
					letter-spacing: 2px;
					line-height: 40px;
					color: #ffffff;
					text-align: left;
				}
			}

			.line-horizontal {
				float: left;
				width: 70px;
				margin-top: 15px;
				height: 7px;

				&.en {
					width: 70px;
				}
			}

			.details {
				p {
					width: 200px;
					float: left;
					color: #ffffff;
				}
			}
		}

		.link {
			position: absolute;
			height: 40px;
			left: 10%;
			bottom: 5%;
			margin-top: 0px;
			transform: translate3d(0, 0, 0);

			.icon {
				width: 30px;
				height: 30px;
				margin-left: 0px;
			}

			.text {
				height: 35px;
				transform: translate3d(0px, 0px, 0px);

				p {

				}
			}
		}
	}

	section.instagram {
		position: absolute;

		.scroll {
			bottom: 3%;
			width: 100%;

			.title {
				width: auto;
				margin-bottom: 0px;
			}

			.button {
				transform: scale(0.7);
			}
		}

		.step {
			position: absolute;
			width: 100%;
			height: 100%;
			float: left;

			&.step-1 {
				.background {
					.image {
						position: absolute;
						top: 0px;
						left: 0px;
						width: 100%;
						height: 100%;
						background-image: url('../assets/images/website/home/instagram/background_mobile.jpg');
						background-size: cover;
					}
				}
			}

			&.step-2 {
				.pictures {
					position: relative;
					top: 0px;
					width: 100%;
					height: auto;
					float: left;

					.picture {
						position: relative;
						width: 50%;
						height: 25%;
						background-size: cover;
						cursor: pointer;
						float: left;

						right: 0%;
						top: 0%;
						left: 0%;
						bottom: 0%;

						.background {
							background-position: top center;
						}
					}
				}
			}
		}

		.text {
			position: absolute;
			top: 14%;
			left: 11%;
			transform: translate3d(0px, 0%, 0px);

			.highlight {
				float: left;
				height: 18px;

				p {
					font-size: 10px;
					letter-spacing: 7px;
				}
			}

			.title {
				width: 100%;
				float: left;
				margin-top: 30px;
				height: 80px;

				p {
					float: left;
					font-size: 40px;
					letter-spacing: 2px;
					line-height: 40px;
					color: #ffffff;
					text-align: left;
				}
			}

			.line-horizontal {
				float: left;
				width: 103px;
				margin-top: 15px;
				height: 7px;

				&.en {
					width: 103px;
				}
			}

			.details {
				p {
					width: 200px;
					float: left;
					color: #ffffff;
				}
			}
		}
	}

	section.footer {
		.container {
			position: relative;
			top: 0%;
			left: 0px;
			width: 100%;
			height: 100%;
			background-color: #ffffff;

			.content {
				position: absolute;
				top: 50%;
				left: 0px;
				width: 100%;
				height: 400px;
				transform: translate3d(0%, -50%, 0px);

				.brand {
					position: relative;
					top: 0px;
					height: 80px;
					display: none;
				}

				.book {
					position: relative;
					width: 100%;
					text-align: center;

					img {
						height: 150px;
						margin: 0 auto;
						margin-left: -10px;
					}

					margin-bottom: 5px;
				}

				.magic-words {
					position: relative;
					top: 0px;

					width: 100%;
					height: 40px;
					float: left;

					.logo {
						display: none;
					}

					.description {
						font-size: 14px;
						letter-spacing: 0px;
						font-weight: bold;
						margin-top: 0px;
						width: 278px;
						text-align: center;
						margin: 0 auto;
						text-transform: capitalize;
					}
				}

				.details {
					position: relative;
					top: 0px;
					width: 100%;
					font-size: 11px;
					letter-spacing: 1px;
					text-align: center;
					color: #000000;
					line-height: 18px;
					float: left;

					p {
						width: 280px;
						margin: 0 auto;

						span {
							display: inline;
						}
					}
				}

				.follow {
					position: relative;
					top: 0px;
					width: 100%;
					text-align: center;
					float: left;
					margin-top: 25px;
					height: 100px;

					.text {
						font-size: 10px;
						opacity: 0.5;
						text-align: center;
						width: 100%;
						letter-spacing: 7px;
					}

					.social {
						position: absolute;
						top: 40px;
						left: 50%;
						transform: translate3d(-50%, 0%, 0);

						.link {
							width: 20px;
							height: 20px;
							border: 1px solid red;
						}
					}
				}

				.download {
					position: absolute;
					top: 300px;

					span {
						font-size: 12px;
						padding: 10px;
						padding-left: 20px;
						padding-right: 20px;
					}
				}

			}

			.follow {
				position: absolute;
				top: none;
				bottom: 10px;
				height: 70px;

				.social {
					position: absolute;
					top: 25px;
					left: 50%;
					transform: translate3d(-50%, 0%, 0);

					.link {
						width: 30px;
						height: 30px;
						img {
							width: 100%;
						}
					}
				}
			}



		}
	}

	section.modal-writers {
		position: fixed;
		opacity: 1;
		visibility: visible;
		width: 100%;
		height: 100%;
		overflow: hidden;
		.writers-tutorial{
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			z-index: 2;
			background-image: url(../assets/images/website/home/writers/writer-tutorial.png);
			background-repeat: no-repeat;
			background-position: center;
			background-size: 60% auto;
			background-color: rgba(0,0,0,0.7);
		}
		.overlay{
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background-color: #000;
			z-index: 1;
			visibility: hidden;
			opacity: 0;
		}
		.content {
			position: relative;
			top: 0px;
			left: 0px;
			width: 100%;
			height: 100%;
			-webkit-overflow-scrolling: touch;
			overflow: scroll;
			z-index: 0;

			.overlay-blur{
				top: 0;
				left: 0;
				position: absolute;
				width: 100%;
				height: 1725px;
				background-color: #000;
				z-index: 1;
				background: url(../assets/images/website/home/writers/gallery-blur.jpg) top center no-repeat;
				background-size: 100% auto;
				pointer-events: none;
				opacity: 0;
				visibility: hidden;
			}
			.thumb {
				opacity: 1;
				position: relative;
				width: 50%;
				height: 25%;
				background-image: url('../assets/images/website/home/writers/picture_1.jpg');
				background-size: cover;
				background-position: center center;
				float: left;
				-webkit-touch-callout: none; /* iOS Safari */
				-webkit-user-select: none;   /* Chrome/Safari/Opera */
				-khtml-user-select: none;    /* Konqueror */
				-moz-user-select: none;      /* Firefox */
				-ms-user-select: none;       /* Internet Explorer/Edge */
				user-select: none;           /* Non-prefixed version, currently
				                              not supported by any browser */


				.background {
					opacity: 1;
				}

				&.size-1 {
					width: 50%;
					height: 25%;
					top: 0px !important;
					left: 0px !important;
				}

				&.size-2 {
					width: 50%;
					height: 25%;
					top: 0px !important;
					left: 0px !important;
				}

				&.size-3 {
					width: 50%;
					height: 25%;
					top: 0px !important;
					left: 0px !important;
				}
			}
		}

		.left {
			position: fixed;
			margin-right: 10px;
			z-index: 1;
			display: block;
			transform: translate3d(0, 0, 0);
		}
	}

	section.details-writers {
		.icon-up {
			position: absolute;
			margin: 0;
			width: 15px;
			left: 50%;
			top: -37px;
			transform: translate3d(-50%,0,0);
		}
		.content {
			.about-view {
				.image {
					width: 100%;
					height: 70%;
					top: 50%;
					transform: translate3d(0,-50%,0);
					transform-origin: center center;
					overflow: initial;
					.background{
						overflow: hidden;
						.thumb{
							.picture{
								background-size: auto 100vh;
							}
						}
					}
				}

				.details {
					top: 50%;
					left: 0%;
					width: 100%;
					height: 100%;
					background-color: transparent;

					.text {
						position: absolute;
						top: 50%;
						left: 0px;
						width: 100%;
						height: auto;
						transform: translate3d(0%, -50%, 0);
						pointer-events: none;

						.chapter {
							position: relative;
							width: 100%;

							p {
								font-size: 12px;
								letter-spacing: 8px;
								line-height: 40px;
								height: 40px;
							}
						}

						.name {
							position: relative;
							width: 100%;

							p{
								font-family: 'HPSimplified-Regular';
								font-size: 25px;
								letter-spacing: 4px;
								line-height: 25px;
								color: #ffffff;

								span {
									display: block;
									height: 25px;
								}

							}
						}

						.info {
							position: relative;
							width: 100%;

							p {
								font-size: 12px;
								line-height: 35px;
								height: 35px;
								color: #ffffff;
							}
						}

						.introduction {
							display: none;
						}

						.link {
							display: none;
						}
					}
				}

				.button_video {
					display: none;
				}

				.timeline {
					display: none;
				}
			}

			.video-view {
				background-color: #000000;

				.chromeless {
					position: absolute;
					top: 50%;
					left: 0%;
					width: 100%;
					height: 200px;
					background-color: #000000;
					transform: translate3d(0%, 0%, 0);
					margin-top: -100px;

					.video {
						position: absolute;
						top: 0px;
						left: 0px;
						width: 100%;
						height: 100%;
					}

					.controls {
						margin-top: -30px;

						.button {
							width: 70px;
							height: 70px;

							canvas {
								position: absolute;
								top: 0px;
								left: 50%;
								width: 70px;
								height: 70px;
								z-index: 1;
								margin-left: -35px;
							}

							img {
								width: 100%;
							}
						}

						.title {
							display: none;
						}
					}
				}
			}

			.pictures-view {

				.controls {
					display: none;
				}

				.bullets {
					position: absolute;
					top: 100%;
					margin-top: -100px;
					width: auto;
					left: 50%;
					transform: translate3d(-50%, 0%, 0);

					ul {
						list-style: none;
						width: auto;
						float: left;

						li {
							position: relative;
							width: 8px;
							height: 8px;
							float: left;
							background-color: #ffffff;
							border-radius: 100%;
							margin-left: 10px;
							transition: background-color 0.4s;

							&:first-child {
								margin-left: 0px;
							}

							&.active {
								background-color: #000000;
							}
						}
					}
				}
			}

			.menu {
				position: absolute;
				top: 100%;
				right: 50%;
				width: auto;
				height: auto;
				margin-top: -60px;
				transform: translate3d(50%, 0%, 0);

				ul {
					width: auto;
					height: 30px;

					li {
						position: relative;
						width: 30px;
						height: 30px;
						float: left;
						margin-bottom: 0px;
						margin-right: 10px;
						cursor: pointer;

						.icon {

							.hover {
								left: 0px;
							}

						}

						&:last-child {
							margin-bottom: 0px;
							margin-right: 0px;
						}
					}
				}
			}
		}

		.right {
			position: fixed;
			margin-right: 10px;
			z-index: 1;
			display: block;
			transform: translate3d(0, 0, 0);
		}
	}
}
