* {
  margin: 0;
  padding: 0;
  vertical-align: middle;
}

html {
  font-size: 0.625em; /* 1 */
  -webkit-text-size-adjust: 0.625em; /* 2 */
  -ms-text-size-adjust: 0.625em; /* 2 */
}

body{
  width: 100%;
  height: 100%;
  overflow: hidden;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizespeed;
  color: #000;
  font-size: 20px;
  overflow: hidden;
}

canvas {
  display: block;
  position: absolute;
  z-index: -1;
}

button{
  border: none;
}


/* =============================================================================
   Typography
   ========================================================================== */

/*
 * Addresses font sizes and margins set differently in IE6/7
 * Addresses font sizes within 'section' and 'article' in FF4+, Chrome, S5
 */

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

h2 {
  font-size: 1.5em;
  margin: 0.83em 0;
}

h3 {
  font-size: 1.17em;
  margin: 1em 0;
}

h4 {
  font-size: 1em;
  margin: 1.33em 0;
}

h5 {
  font-size: 0.83em;
  margin: 1.67em 0;
}

h6 {
  font-size: 0.75em;
  margin: 2.33em 0;
}

a {
  cursor: pointer;
}

a:link {
    color: #fff;
}
a:visited {
    color: #fff;
}
a:hover {
    color: #fff;
}
a:active {
    color: #fff;
}

::-webkit-input-placeholder {
   color: #000;
}

:-moz-placeholder { /* Firefox 18- */
   color: #000;
}

::-moz-placeholder {  /* Firefox 19+ */
   color: #000;
}

:-ms-input-placeholder {
   color: #000;
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none;   /* Chrome/Safari/Opera */
    -khtml-user-select: none;    /* Konqueror */
    -moz-user-select: none;      /* Firefox */
    -ms-user-select: none;       /* Internet Explorer/Edge */
    user-select: none;           /* Non-prefixed version, currently not supported by any browser */
}
