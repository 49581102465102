@font-face {
  font-family: 'HPSimplified-Bold';
  src:  url('../assets/fonts/HPSimplified-Bold.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/HPSimplified-Bold.woff') format('woff'),
        url('../assets/fonts/HPSimplified-Bold.ttf') format('truetype'),
        url('../assets/fonts/HPSimplified-Bold.svg#HPSimplified-Bold') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HPSimplified-BoldItalic';
  src:  url('../assets/fonts/HPSimplified-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/HPSimplified-BoldItalic.woff') format('woff'),
        url('../assets/fonts/HPSimplified-BoldItalic.ttf') format('truetype'),
        url('../assets/fonts/HPSimplified-BoldItalic.svg#HPSimplified-BoldItalic') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HPSimplified-Italic';
  src:  url('../assets/fonts/HPSimplified-Italic.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/HPSimplified-Italic.woff') format('woff'),
        url('../assets/fonts/HPSimplified-Italic.ttf') format('truetype'),
        url('../assets/fonts/HPSimplified-Italic.svg#HPSimplified-Italic') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HPSimplified-Light';
  src:  url('../assets/fonts/HPSimplified-Light.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/HPSimplified-Light.woff') format('woff'),
        url('../assets/fonts/HPSimplified-Light.ttf') format('truetype'),
        url('../assets/fonts/HPSimplified-Light.svg#HPSimplified-Light') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HPSimplified-Italic';
  src:  url('../assets/fonts/HPSimplified-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/HPSimplified-LightItalic.woff') format('woff'),
        url('../assets/fonts/HPSimplified-LightItalic.ttf') format('truetype'),
        url('../assets/fonts/HPSimplified-LightItalic.svg#HPSimplified-LightItalic') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HPSimplified-Regular';
  src:  url('../assets/fonts/HPSimplified-Regular.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/HPSimplified-Regular.woff') format('woff'),
        url('../assets/fonts/HPSimplified-Regular.ttf') format('truetype'),
        url('../assets/fonts/HPSimplified-Regular.svg#HPSimplified-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}