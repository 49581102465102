/*=================================
=            VARIABLES            =
=================================*/

$button_default_text_color 		: #ffffff;
$background_book_color			: #f6f6f6;
$page_number_book_color			: #b3b3b3;
$page_paper_book_color			: #ffffff;
$page_pager_book_shadow			: #dddddd;
$page_pager_book_text_color		: #000000;
$button_default_blue_color		: #5674ff;
$button_default_blue_color		: #5674ff;
$microphone_text_color			: #5674ff;

/*===============================
=            MIXIN's            =
===============================*/

@keyframes cursorAnimation {
  0%   { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
}

/*====================================================
=            RESET, DEFAULT's and HELPERS            =
====================================================*/
@import "normalize";
@import "common";
@import "fonts";


/*================================
=            IMPORT's            =
================================*/

@import "desktop_large_screen";

@media only screen and (max-width:1500px) {
	@import "desktop_default_screen";
}
@media only screen and (max-device-width:586px) and (orientation: portrait) {
	@import "mobile_portrait";
}
@media screen and (device-aspect-ratio: 2/3) {
	@import "mobile_small_portrait";
}
@media only screen and (max-device-width:736px) and (orientation: landscape) {
	@import "mobile_landscape";
}
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
	@import "tablet_portrait";
}
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
	@import "tablet_landscape";
}