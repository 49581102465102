body {
	// background-image: url('../assets/images/website/prisma/bg2.jpg');
	background-repeat: no-repeat;
	background-position: top center;
	overflow: hidden;
}

html {
	overflow: hidden;
}

* {
	outline: none;
	-webkit-tap-highlight-color: rgba(0,0,0,0);
  	-webkit-tap-highlight-color: transparent;
}

.desktop {
	display: block;
}

.mobile {
	display: none;
}

.tablet {
	display: none;
}

.app {
	opacity: 0;
	overflow: hidden;

	.video-player {
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
		overflow: hidden;

		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;

		.video-player-movie {
			cursor: pointer;
			// pointer-events: none;
		}

		.video-player-control-bar {
			position: absolute;
			top: 100%;
			left: 0px;
			width: 100%;
			height: 30px;
			margin-top: -30px;
			background-color: rgba(0, 0, 0, 0.3);
			display: none;

			.video-player-control-bar-seek {
				position: absolute;
				top: 0px;
				left: 0%;
				width: 100%;
				height: 2px;
				cursor: pointer;
				overflow: hidden;
				background-color: rgba(0, 0, 0, 0);

				.video-player-control-bar-seek-background {
					position: absolute;
					top: 0px;
					left: 0%;
					width: 100%;
					height: 10px;
					background-color: rgba(26, 142, 212, 0.3);
				}

				.video-player-control-bar-seek-progress {
					position: absolute;
					top: 0px;
					left: 0%;
					width: 100%;
					height: 10px;
					background-color: #ffffff;
					opacity: 0.2;
					pointer-events: none;
				}

				.video-player-control-bar-seek-time {
					position: absolute;
					top: 0px;
					left: 0%;
					width: 100%;
					height: 10px;
					background-color: rgba(26, 142, 212, 1);
					pointer-events: none;
				}
			}

			.video-player-control-options {
				position: absolute;
				top: 2px;
				left: 0%;
				width: 100%;
				height: 18px;
			}
		}
	}

	.scroll-bar {
		position: absolute;
		top: 0px;
		right: 0px;
		width: 16px;
		height: 100%;
		opacity: 0;

		.background {
			position: absolute;
			top: 0px;
			left: 0px;
			width: 100%;
			height: 100%;
			background-color: #000000;
			opacity: 0;
		}

		.scrub {
			position: absolute;
			width: 10px;
			height: 100px;
			margin-left: 3px;
			background-color: #000000;
			border-radius: 20px;
			opacity: 0.3;
			cursor: pointer;
		}
	}

	section {
		position: relative;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
		opacity: 0;
		visibility: hidden;
	}

	section.navigation {
		position: absolute;
		width: 100%;
		height: 62px;

		.menu {
			position: relative;
			height: 38px;
			width: 100%;
			top: 50%;
			transform: translate3d(0, -50%, 0);

			.left {
				width: auto;
				margin-left: 50px;
				float: left;

				.logo {
					width: auto;
					float: left;
					cursor: pointer;
					opacity: 0;

					img {
						height: 38px;
					}

					.normal {

					}

					.invert {
						top: 0px;
						left: 0px;
						position: absolute;
						opacity: 0;
					}
				}

				.title {
					font-family: 'HPSimplified-Regular';
					float: left;
					color: $button_default_text_color;
					padding-left: 16px;
					font-size: 16px;
					font-weight: bold;
					display: none;

					.text {
						float: left;
						line-height: 40px;
						font-weight: normal;
					}

					.cursor {
						width: 1px;
						height: 20px;
						border-right: 1px solid #fff;
						animation: cursorAnimation 1s infinite;
						float: left;
						margin-top: 10px;
						margin-left: 5px;
					}
				}
			}

			.right {
				float: right;
				margin-right: 50px;

				.button {
					opacity: 0;
					width: auto;
					float: left;
					cursor: pointer;

					.button_container {
						transform-style: preserve-3d;
						perspective: 1000;
						border: 2px solid #1a8ed4;
						height: 38px;
						width: 38px;
						border-radius: 50%;
						position: relative;
						box-sizing: border-box;
					}

					img {
						backface-visibility: hidden;
						position: absolute;
						top: 50%;
						left: 50%;
						width: 58%;
						transform: translate3d(-50%,-50%,0);
					}

					.icon {
						opacity: 1;
					}

					.invert {
						opacity: 0;
					}

					.hover {
						position: absolute;
						top: 0px;
						left: 0px;
						transform: rotateY(0deg);
						opacity: 0;
					}
				}
			}
		}
	}

	section.share {
		position: absolute;
		top: 100%;
		width: 100%;
		height: 62px;
		margin-top: -70px;
		pointer-events: none;
		display: block;

		.menu {
			position: relative;
			height: 38px;
			width: 100%;
			top: 50%;
			transform: translate3d(0, -50%, 0);

			.right {
				float: right;
				margin-right: 50px;
				pointer-events: auto;
				.button {
					opacity: 0;
					width: auto;
					float: left;
					cursor: pointer;

					.button_container {
						transform-style: preserve-3d;
						perspective: 1000;
						border: 2px solid #1a8ed4;
						height: 38px;
						width: 38px;
						border-radius: 50%;
						position: relative;
						box-sizing: border-box;
					}

					img {
						backface-visibility: hidden;
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate3d(-50%,-50%,0);
						height: 50%;
					}

					.icon {
						opacity: 1;
					}

					.invert {
						opacity: 0;
					}

					.hover {
						position: absolute;
						top: 0px;
						left: 0px;
						transform: rotateY(0deg);
						opacity: 0;
					}


					&.facebook {
						position: absolute;
						margin-top: -50px;
					}

					&.twitter {
						position: absolute;
						margin-top: -100px;
					}
				}
			}
		}
	}

	section.menu {
		position: fixed;
		top: 0px;
		left: 100%;
		width: 720px;
		height: 100%;
		background-color: #000000;
		opacity: 1;
		visibility: visible;
		z-index: 21;

		.top {
			position: absolute;
			height: 62px;
			width: 100%;
			float: left;

			.container {
				position: relative;
				height: 38px;
				width: 100%;
				top: 50%;
				transform: translate3d(0, -50%, 0);
				float: right;

				.button {
					float: right;
					cursor: pointer;
					margin-right: 50px;

					.button_container {
						transform-style: preserve-3d;
						perspective: 1000;
						border: 2px solid #1a8ed4;
						height: 38px;
						width: 38px;
						border-radius: 50%;
						position: relative;
						box-sizing: border-box;
					}

					img {
						backface-visibility: hidden;
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate3d(-50%,-50%,0);
					}

					.icon,
					.invert {
						width: 40%;
					}

					.icon {
						opacity: 1;
					}

					.invert {
						opacity: 0;
					}

					.hover {
						position: absolute;
						top: 0px;
						left: 0px;
						transform: rotateY(0deg);
						opacity: 0;
					}
				}
			}

		}
		.content {
			// position: absolute;
			// top: 45%;
			// left: 50%;
			// width: 80%;
			// height: calc(100% - 173px);
			// transform: translate3d(-50%, calc(-50% - -40px), 0);
			position: absolute;
			bottom: 120px;
			left: 50%;
			width: 80%;
			height: 70%;
			transform: translate3d(-50%, 0, 0);
			overflow: auto;
			display: table;
			&::-webkit-scrollbar{
				display: none;
			}

			.wrap-list {
				position: relative;
				list-style: none;
				width: 100%;
				display: table-cell;

				.list-item {
					position: relative;
					width: 100%;
					height: 59px;
					overflow: hidden;
					cursor: pointer;

					.title {
						position: relative;

						p {
							font-family: 'HPSimplified-Light';
							width: 100%;
							color: #ffffff;
							line-height: 59px;
							text-align: center;
							text-transform: uppercase;
							font-size: 14px;
							letter-spacing: 11px;
							font-weight: bold;
						}
					}

					.bar {
						position: absolute;
						top: 50%;
						width: 100px;
						height: 1px;
						background-color: #017cce;

						&.left {
							left: 0%;
							transform: translate3d(-100%, 0%, 0);
						}

						&.right {
							right: 0%;
							transform: translate3d(100%, 0%, 0);
						}
					}

					.background {
						position: absolute;
						top: 0px;
						left: 0px;
						width: 100%;
						height: 100%;
						background-image: url('../assets/images/website/menu/button_round.png');
						background-size: auto 100%;
						background-position: center center;
						background-repeat: no-repeat;
					}

					&.border {
						height: 50px;
						margin-top: 10px;

						.title {
							p {
								line-height: 50px;
							}
						}
					}

					&.active {
						.title {
							p {
								color: #017cce;
								text-decoration: underline;
							}
						}
					}

					&:nth-child(1){
						.bar {
							width: 130px;
						}
					}
					&:nth-child(2){
						.bar {
							width: 100px;
						}
					}
					&:nth-child(3){
						.bar {
							width: 140px;
						}
					}
					&:nth-child(4){
						.bar {
							width: 40px;
						}
					}
					&:nth-child(5){
						.bar {
							width: 100px;
						}
					}
					&:nth-child(6){
						.bar {
							width: 150px;
						}
					}
					&:nth-child(8){
						.bar {
							width: 50px;
						}
					}
				}

				li.subitem {
					height: auto;

					.container {
						position: relative;
					}

					.item {
						font-family: 'HPSimplified-Light';
						position: relative;
						height: 35px;
						color: #ffffff;
						width: 100%;
						text-transform: uppercase;
						font-size: 12px;
						font-weight: bold;
						line-height: 35px;
						letter-spacing: 6px;
						display: block;

						.user {
							width: 200px;
							float: left;
							text-align: left;
							overflow: hidden;

							p {
								position: absolute;
								width: 1000px;
							}
						}
						.charpter {
							position: absolute;
							width: 150px;
							right: 0;
							text-align: right;
							background-color: #000000;
						}
					}
				}
			}
		}
		&.writers-menu{
			.content {
				display: block;
				.wrap-list{
					display: block;
				}
			}

		}

		.bottom {
			position: absolute;
			bottom: 0%;
			height: 110px;
			width: 100%;
			float: left;
			background-color: #000000;

			.line {
				position: absolute;
				top: 0%;
				left: 0%;
				width: 100%;
				height: 1px;
				background-color: #ffffff;
				opacity: 0.2;
			}

			.container {
				position: absolute;
				height: 30px;
				width: 520px;
				left: 50%;
				top: 50%;
				transform: translate3d(-50%, -50%, 0);

				.button {
					width: 180px;
					float: left;
					cursor: pointer;
					margin-right: 50px;
					line-height: 30px;

					.button_container {
						transform-style: preserve-3d;
						perspective: 1000;
						border: 2px solid #1a8ed4;
						height: 29px;
						width: 29px;
						border-radius: 50%;
						position: relative;
						box-sizing: border-box;
						display: inline-block;
						margin-right: 4px;
					}

					img {
						backface-visibility: hidden;
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate3d(-50%,-50%,0);
						margin-right: 4px;
						width: 45%;
					}

					.icon {
						opacity: 1;
					}

					.invert {
						opacity: 0;
					}

					.hover {
						position: absolute;
						top: 0px;
						left: 0px;
						transform: rotateY(0deg);
						opacity: 0;
					}

					.text {
						display: inline-block;
						p {
							font-family: 'HPSimplified-Light';
							font-size: 16px;
							color: #ffffff;
							font-weight: bold;
						}
					}
				}

				// .button {
				// 	width: 180px;
				// 	float: left;
				// 	cursor: pointer;
				// 	margin-right: 50px;
				// 	line-height: 30px;

				// 	.icon {
				// 		position: relative;
				// 		width: 29px;
				// 		height: 29px;
				// 		margin-right: 10px;
				// 		float: left;
				// 		transform-style: preserve-3d;
				// 		perspective: 1000;

				// 		img {
				// 			position: absolute;
				// 			width: 100%;
				// 			height: 100%;
				// 			backface-visibility: hidden;
				// 		}

				// 		.hover {
				// 			opacity: 0;
				// 		}

				// 		.hover-down {
				// 			opacity: 0;
				// 		}
				// 	}

				// 	.text {
				// 		float: left;

				// 		p {
				// 			font-family: 'HPSimplified-Light';
				// 			font-size: 16px;
				// 			color: #ffffff;
				// 			font-weight: bold;
				// 		}
				// 	}
				// }

				.language {
					width: auto;
					float: left;
					cursor: pointer;
					line-height: 30px;

					.idiom {
						position: relative;
						float: left;

						p {
							font-family: 'HPSimplified-Regular';
							font-size: 12px;
							color: #ffffff;
							font-weight: bold;
						}

						&.active {
							p {
								text-decoration: underline;
							}
						}
					}

					.divisor {
						position: relative;
						width: 1px;
						height: 30px;
						background-color: #1a8ed4;
						float: left;
						margin-left: 10px;
						margin-right: 10px;
						transform: rotate(15deg);
					}
				}

				.title {
					position: relative;
					height: 50px;

					p {
						font-family: 'HPSimplified-Light';
						width: 100%;
						color: #ffffff;
						line-height: 59px;
						text-align: center;
						text-transform: uppercase;
						font-size: 14px;
						letter-spacing: 11px;
						font-weight: bold;
						text-transform: uppercase;
					}
				}

				.stores {
					position: relative;
					width: 100%;

					.download {
						position: relative;
						text-align: center;
						width: 210px;
						margin: 0 auto;

						.store {
							height: 50px;
							float: left;
							margin-right: 10px;

							img {
								width: 100px;
							}

							&:last-child {
								margin-right: 0;
							}
						}
					}
				}

				.options {
					position: relative;
					width: 100%;
					height: 50px;

					.block {
						width: 200px;
						margin: 0 auto;
						height: 50px;

						.language {
							position: relative;
							height: 40px;
							width: 80px;
							background-image: url('../assets/images/website/menu/button_language.png');
							background-size: 100% 100%;
							float: left;

							.text {
								position: absolute;
								p {
									font-family: 'HPSimplified-Regular';
									font-size: 12px;
									color: #ffffff;
									font-weight: bold;
									line-height: 40px;
								}

								&.left {
									left: 15px;
								}

								&.right {
									right: 15px;
								}
							}

							.scrub {
								position: absolute;
								top: 3px;
								left: 3px;
								width: 35px;
								height: 34px;
								border-radius: 100%;
								background-color: #1a8ed4;

								transform: translate3d(39px, 0%, 0);
							}
						}

						.social {
							float: right;

							.item {
								float: left;
								margin-right: 5px;

								img {
									height: 40px;
								}

								&:last-child {
									margin-right: 0px;
								}
							}

						}

					}

				}
			}

		}
	}

	div.menu-overlay {
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
		background-color: #000000;
		z-index: 20;
		transform: translate3d(100%, 0%, 0);
	}

	section.home {
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
		overflow: auto;
		-webkit-overflow-scrolling: touch;
		overflow: hidden;

		.text-underline {
			width: auto;
			float: left;
			margin-right: 15px;

			.content {
				width: auto;
			}

			.underline {
				position: relative;
				width: 100%;
				height: 1px;
				margin-top: 4px;
				overflow: hidden;

				.background-underline {
					position: absolute;
					top: 0px;
					left: 0px;
					width: 100%;
					height: 100%;
					background-color: #1a8ed4;
				}
			}
		}

		.line-horizontal {
			margin: 0 auto;
			width: 100px;
			height: 1px;
			background-color: #1a8ed4;
		}

		.hashtag {
			position: absolute;
			top: 50%;
			left: 95px;
			width: 8px;
			// overflow: hidden;
			display: none;

			img {
				width: 100%;
			}
		}

		.hashtag-full {
			position: absolute;
			top: 50%;
			left: 95px;
			width: 8px;
			pointer-events: none;
			opacity: 0;

			img {
				width: 100%;
			}
		}

		.bars {
			position: absolute;
			width: 100%;
			height: 100%;
			pointer-events: none;
			transform: translate3d(0, 0, 0);
			z-index: 12;
			display: none;

			.bar {
				position: absolute;
				width: 30px;
				height: 100%;
				background-color: #000000;
			}

			.left {
				top: 0px;
				left: 0px;
			}

			.right {
				top: 0px;
				right: 0px;
			}

			.top {
				top: 0px;
				left: 0px;
				width: 100%;
				height: 55px;
				display: none;
			}

			.bottom {
				left: 0px;
				bottom: 0%;
				width: 100%;
				height: 30px;
				display: none;
			}
		}

		.deactive {
			display: none;
		}

		section {
			visibility: visible;
			opacity: 1;
		}
	}

	section.welcome {
		position: absolute;
		background-color: #ffffff;
		color: #000000;

		.brand {
			position: absolute;
			width: auto;
			top: 50%;
			left: 50%;
			width: 514px;
			margin-left: -257px;
			margin-top: -50px;

			.logo {
				width: 100%;
				text-align: center;
				opacity: 0;
				img {
					margin-left: 0px;
					display: none;
				}
			}

			.title {
				width: auto;
				margin-top: 22px;

				.text-underline {
					overflow: hidden;

					.content {
						width: 514px;
					}

					p {
						width: 700px;
						font-size: 15px;
						font-family: 'HPSimplified-Regular';
						letter-spacing: 9.1px;
						text-transform: uppercase;
						text-align: left;
						height: 15px;
					}

					.underline {
						width: 100%;
						background-color: #1a8ed4;
					}

				}

			}

			&.en {
				.title {
					.text-underline {
						p {
							letter-spacing: 10.8px;
						}
					}
				}
			}
		}

		.magic-words {
			position: absolute;
			width: 100%;
			top: 35%;

			.logo {
				width: 100%;
				text-align: center;
				opacity: 0;
				background-color: #ffffff;
				transform: translate3d(0, 0, 0);

				video {
					position: absolute;
					top: 0px;
					left: 0px;
					width: 680px;
					left: 50%;
					opacity: 1;
					margin-left: -340px;
					margin-top: 55px;
					background-color: #ffffff;
					transform: translate3d(0, 0, 0);
				}
				img {
					opacity: 0;
				}
			}

			.description {
				font-size: 14px;
				font-family: 'HPSimplified-Regular';
				letter-spacing: 20.9px;
				text-transform: uppercase;
				text-align: left;
				font-weight: bold;
				color: #696969;
				z-index: 10;
				width: 685px;
				margin: 0 auto;
				padding-left: 20px;
				margin-top: -85px;
				overflow: hidden;

				p {
					position: absolute;
				}

				&.safari {
					letter-spacing: 19.7px;
				}

				&.firefox {
					letter-spacing: 20.5px;
				}
			}

			&.en {
				.description {
					letter-spacing: 26.9px;
					font-size: 12px;

					&.safari {
						letter-spacing: 25.8px;
					}

					&.firefox {
						letter-spacing: 26.5px;
					}
				}
			}
		}
	}

	section.introduction {
		position: absolute;
		transform: translate3d(0, 100%, 0);
		overflow: hidden;
		background-color: #000000;
		// opacity: 0.5;

		.video {
			position: absolute;
			top: 0px;
			left: 0px;
			width: 100%;
			height: 100%;

			.player {
				position: relative;
				top: 0px;
				left: 0px;
				width: 100%;
				height: 100%;
			}

			.top {
				position: absolute;
				top: 0px;
				left: 0px;
				width: 100%;
				height: 90px;
				background-color: #000000;
			}
		}

		.page-content {
			position: absolute;
			top: 0px;
			left: 0px;
			width: 100%;
			height: 100%;
		}

		.google {
			position: absolute;
			bottom: 10%;
			left: 95px;
			width: 130px;
			pointer-events: none;
			margin-bottom: 25px;
			display: none;

			img {
				float: left;
				width: 100%;
				opacity: 0;
			}
		}

		.text {
			position: absolute;
			width: 100%;
			top: 40%;
			transform: translate3d(0px, -50%, 0px);
			text-align: center;

			.highlight {
				position: absolute;
				height: 50px;
				left: 50%;
				width: 203px;
				margin-left: -101.5px;
				margin-top: -30px;

				.content {
					float: left;
					width: auto;
					/*
					p {
						font-family: 'HPSimplified-Regular';
						color: #ffffff;
						font-size: 15px;
						line-height: 15px;
					}
					*/

					img {
						width: 100%;
					}

				}

				.cursor {
					float: left;
					height: 15px;
					width: 1px;
					border-left: 1px solid #ffffff;
					margin-left: 10px;
					animation: cursorAnimation 1s infinite;
				}
			}

			.title {
				position: relative;
				letter-spacing: 6px;
				line-height: 70px;
				height: 160px;
				margin-top: 50px;

				p {
					font-family: 'HPSimplified-Light';
					font-size: 67px;
					color: #ffffff;
					text-transform: uppercase;

					span {
						position: absolute;
						display: block;
						height: 70px;

						&:nth-child(1) {
							position: absolute;
						    top: 0px;
						    left: 50%;
						    width: auto;
						    overflow: hidden;
						    transform: translate3d(-50%,0,0);
						    white-space: nowrap;
						    text-align: left;
						}
						&:nth-child(2) {
							position: absolute;
							top: 70px;
							left: 50%;
							width: auto;
							overflow: hidden;
						    transform: translate3d(-50%,0,0);
						    white-space: nowrap;
							text-align: left;
						}
					}
				}
			}

			.description {
				margin-top: 10px;
				line-height: 25px;
				letter-spacing: 1.5px;

				p {
					font-family: 'HPSimplified-Light';
					font-size: 14px;
					color: #ffffff;

					span {
						display: block;
						opacity: 0;
					}
				}
			}
		}

		.scroll {
			position: absolute;
			bottom: 10%;
			width: 100%;
			transform: translate3d(0%, 0%, 0);

			.title {
				width: 220px;
				margin: 0 auto;
				margin-bottom: 25px;
				height: 20px;
				overflow: hidden;

				p {
					width: auto;
					font-size: 10px;
					font-family: 'HPSimplified-Regular';
					letter-spacing: 5px;
					text-transform: uppercase;
					text-align: left;
					color: #ffffff;
					position: absolute;
				}

			}

			.button {
				position: relative;
				width: 69px;
				height: 69px;
				margin: 0 auto;
				cursor: pointer;
				pointer-events: auto;

				canvas {
					position: absolute;
					top: 50%;
					left: 50%;
					width: 100px;
					height: 100px;
					transform: translate3d(-50%, -50%, 0);
				}

				img {
					// display: none;
					transform: translate3d(0, 0, 0);
				}
			}
		}
	}

	section.documentary {
		position: absolute;
		transform: translate3d(0, 100%, 0);
		color: #ffffff;
		// overflow: hidden;
		background-color: transparent;

		.background {
			position: absolute;
			top: 0px;
			left: 0px;
			width: 100%;
			height: 100%;
			background-image: url('../assets/images/website/home/documentary/background_view.jpg');
			background-size: cover;
			background-position: center center;
			opacity: 0;
			background-color: #000000;
			pointer-events: none;
		}

		.page-content {
			position: absolute;
			top: 0px;
			left: 0px;
			width: 100%;
			height: 100%;
		}

		.graph {
			position: absolute;
			width: 1px;
			height: 1px;
			pointer-events: none;
			transform: translate3d(0, 0, 0);
			overflow: hidden;

			.graph-content {
				position: absolute;
				top: 0px;
				left: 0px;
				width: 100%;
				height: 100%;
			}

			.graph-bar {
				position: absolute;
				top: 0px;
				left: 0px;
				width: 100%;
				height: 100%;
				background-color: #1a8ed4;
			}

			&.graph-1 {
				top: 30%;
				right: 5%;
				width: 20%;
				height: 10px;
			}

			&.graph-2 {
				bottom: 2%;
				right: 20%;
				width: 1px;
				height: 6%;
				opacity: 0.5;
			}

			&.graph-3 {
				bottom: 2%;
				right: 25%;
				width: 1px;
				height: 6%;
				opacity: 0.5;
			}
		}

		.chromeless {
			position: absolute;
			top: 55%;
			left: 50%;
			width: 60%;
			height: 60%;
			background-color: #000000;
			transform: translate3d(-50%, -50%, 0px);

			.video {
				position: absolute;
				top: 0px;
				left: 0px;
				width: 100%;
				height: 100%;
				transform: translate3d(0%, 0%, 0px);

				.player {
					position: relative;
					top: 0px;
					left: 0px;
					width: 100%;
					height: 100%;
					transform: translate3d(0%, 0%, 0px);
					pointer-events: none;
				}
			}

			.controls {
				position: absolute;
				top: 50%;
				left: 50%;
				width: 180px;
				height: auto;
				margin-left: -90px;
				margin-top: -66px;
				overflow: hidden;
				transform: translate3d(0%, 0%, 0px);
				pointer-events: auto;

				.button {
					width: 133px;
					height: 133px;
					cursor: pointer;
					margin: 0 auto;

					canvas {
						width: 133px;
						height: 133px;
					}

					img {
						width: 100%;
						height: 100%;
					}
				}

				.title {
					width: 160px;
					text-align: center;
					margin: 0 auto;
					margin-top: 50px;
					overflow: hidden;

					p {
						width: 100%;
						font-size: 12px;
						font-family: 'HPSimplified-Regular';
						letter-spacing: 9px;
						text-transform: uppercase;
						text-align: left;
						font-weight: bold;
						color: #ffffff;
						height: 15px;
					}

					span {
						width: auto;
						font-size: 14px;
						font-family: 'HPSimplified-Light';
						text-align: center;
						font-weight: bold;
						color: #ffffff;
					}

					.line-horizontal {
						width: 100%;
						margin-top: 0px;
						margin-bottom: 5px;
						background-color: #ffffff;
					}

				}
			}

			iframe {
				pointer-events: auto;
				opacity: 0;
			}
		}

		.text {
			position: absolute;
			top: 50%;
			left: 11%;
			transform: translate3d(0px, -50%, 0px);
			overflow: hidden;

			.highlight {
				overflow: hidden;
				width: 200px;
				height: 20px;

				p {
					font-family: 'HPSimplified-Regular';
					font-size: 14px;
					color: #1a8ed4;
					text-transform: uppercase;
					letter-spacing: 9px;
				}

				.text-underline {

					.underline {
						margin-top: 0px;
					}
				}
			}

			.title {
				margin-top: 45px;
				height: 225px;

				p {
					font-family: 'HPSimplified-Light';
					font-size: 77px;
					color: #ffffff;
					text-transform: uppercase;
					letter-spacing: 3px;
					line-height: 75px;

					span {
						display: block;
					}
				}
			}

			.line-horizontal {
				margin: 0;
				margin-top: 20px;
				width: 200px;
				height: 10px;

				&.en {
					width: 170px;
				}
			}
		}
	}

	section.project {
		position: absolute;
		background-color: transparent;
		color: #ffffff;
		transform: translate3d(0, 100%, 0);
		overflow: hidden;

		.background {
			position: absolute;
			top: 0px;
			left: 0px;
			width: 100%;
			height: 100%;

			.cover-text {
				position: absolute;
				top: 50%;
				left: 50%;
				width: 50%;
				height: 50%;

				background-color: #ffffff;

				.container {
					position: absolute;
					top: 50%;
					width: 100%;
					height: auto;
					transform: translate3d(0px, -50%, 0px);
					opacity: 0;

					.title {
						position: absolute;
						display: block;
						height: 22px;
						margin: 0 auto;
						margin-bottom: 42px;
						left: 50%;
						overflow: hidden;
						transform: translate3d(-50%, 0, 0);
						text-rendering: geometricPrecision;

						.text-underline {
							margin-right: 0px;

							p {
								font-family: 'HPSimplified-Regular';
								font-size: 14px;
								text-transform: uppercase;
								letter-spacing: 10px;
								color: #1a8ed4;
							}

							.underline {
								margin-top: 0px;
							}
						}
					}

					.description {
						width: 100%;
						float: none;
						margin-top: 40px;
						height: auto;

						p {
							width: 55%;
							min-width: 300px;
							margin: 0 auto;
							text-align: center;

							font-family: 'HPSimplified-Light';
							font-size: 16px;
							color: #000000;
							line-height: 26px;
						}
					}

				}
			}

			.cover-image {
				.container-1, .container-3, .container-5, .container-7 {
					position: absolute;
					top: 0px;
					left: 0px;
					width: 50%;
					height: 100%;

				}

				.container-2, .container-4, .container-6, .container-8 {
					position: absolute;
					top: 0%;
					left: 50%;
					width: 50%;
					height: 50%;
				}

				.container-3, .container-5, .container-7 {
					transform: translate3d(0%, 100%, 0);
					opacity: 0;
				}

				.container-4, .container-6, .container-8 {
					transform: translate3d(-100%, 0%, 0);
					opacity: 0;
				}

				.container {
					overflow: hidden;
					background-color: #000000;
					overflow: hidden;

					.player {
						position: absolute;
						top: 0px;
						left: 0px;
						width: 100%;
						height: 100%;
					}

					.background {
						position: relative;
						top: 0px;
						left: 0px;
						width: 100%;
						height: 100%;
					}

					.background-1 {
						background-image: url('../assets/images/website/home/project/background_1.jpg');
						background-size: cover;
						background-position: center center;
					}

					.background-2 {
						background-image: url('../assets/images/website/home/project/background_2.jpg');
						background-size: cover;
						background-position: center center;
					}

					.background-3 {
						background-image: url('../assets/images/website/home/project/background_3.jpg');
						background-size: cover;
						background-position: center center;
					}

					.background-4 {
						background-image: url('../assets/images/website/home/project/background_4.jpg');
						background-size: cover;
						background-position: center center;
					}

					.background-5 {
						background-image: url('../assets/images/website/home/project/background_5.jpg');
						background-size: cover;
						background-position: center center;
					}

					.background-6 {
						background-image: url('../assets/images/website/home/project/background_6.jpg');
						background-size: cover;
						background-position: center center;
					}
				}

				.container-3, .container-5, .container-7 {
					background-color: #000000;
				}

				.container-4, .container-6, .container-8 {
					background-color: #000000;
				}
			}

			.book {
				position: absolute;
				top: 50%;
				left: 50%;
				width: 607px;
				height: 726px;
				transform: translate3d(-50%, -50%, 0px);

				background-image: url('../assets/images/website/home/project/background_book.png');
				background-repeat: no-repeat;
				background-position: center center;
				background-size: cover;
			}
		}

		.text {
			position: absolute;
			top: 30%;
			left: 11%;
			transform: translate3d(0px, -50%, 0px);
			overflow: hidden;


			.highlight {
				width: 260px;

				p {
					font-family: 'HPSimplified-Regular';
					font-size: 14px;
					color: #1a8ed4;
					text-transform: uppercase;
					letter-spacing: 9px;
				}

				.text-underline {

					.underline {
						margin-top: 0px;
					}
				}
			}

			.title {
				margin-top: 45px;
				height: 150px;

				p {
					font-family: 'HPSimplified-Light';
					font-size: 77px;
					color: #ffffff;
					text-transform: uppercase;
					letter-spacing: 3px;
					line-height: 75px;

					span {
						display: block;
					}
				}
			}

			.line-horizontal {
				margin: 0;
				margin-top: 20px;
				width: 90px;
				height: 10px;

				&.en {
					width: 120px;
				}
			}
		}

		.list {
			position: absolute;
			top: 75%;
			left: 11%;
			margin-top: -55px;

			ul {
				list-style: none;

				li {
					position: relative;
					display: block;
					height: 50px;
					width: 300px;
					cursor: pointer;

					.bullet {
						position: absolute;
						top: 0px;
						left: 0px;
						width: 7px;
						height: 7px;
						border-radius: 100%;
						background-color: #ffffff;
					}

					.icon {
						position: absolute;
						top: -9px;
						left: -9px;
						width: 25px;
						height: 25px;
						opacity: 0;

						.image {
							position: absolute;
							top: 0px;
							left: 0px;
							width: 100%;
							height: 100%;
							background-image: url('../assets/images/website/home/project/list_bullet.png');
							background-repeat: no-repeat;
							background-size: 100% 100%;
							transform: translate3d(0, 0, 0);
						}
					}

					.line {
						position: absolute;
						top: 0px;
						left: 3px;
						width: 1px;
						height: 50px;
						opacity: 0.2;
						overflow: hidden;

						.line-background {
							position: absolute;
							top: 0px;
							left: 0px;
							width: 100%;
							height: 100%;
							background-color: #ffffff;
						}
					}

					.title {
						position: absolute;
						top: -3px;
						left: 30px;
						width: 100%;
						font-family: 'HPSimplified-Regular';
						font-size: 12px;
						text-transform: uppercase;
						letter-spacing: 7px;
						color: #ffffff;

						.text-underline {
							margin-right: 0px;

							p {

							}

							.underline {
								margin-top: 0px;
								opacity: 0;

								.background-underline {
									background-color: #ffffff;
								}
							}
						}
					}

					&:last-child {
						.line {
							display: none;
						}
					}
				}
			}
		}

		.hashtag {
			left: 96%;
			top: 40%;
		}
	}

	section.technology {
		position: absolute;
		color: #ffffff;
		height: 100%;
		overflow: hidden;
		transform: translate3d(0, 100%, 0);

		.step {
			position: absolute;
			top: 0px;
			left: 0px;
			width: 100%;
			height: 100%;
		}

		.divisor {
			position: relative;
			width: 100%;
			height: 71px;
			float: left;
		}

		&.technology-2 {
			pointer-events: none;
		}

		.step-right {

			.background-white {
				position: absolute;
				top: 0px;
				left: 50%;
				width: 50%;
				height: 100%;
				background-color: #ffffff;
			}

			.background {
				position: absolute;
				top: 0px;
				left: 0px;
				width: 100%;
				height: 100%;
				pointer-events: none;

				.graph {
					position: absolute;
					bottom: 10%;
					left: 25%;
					height: 98px;
					width: 1px;
					background-color: #ffffff;
					opacity: 0.2;

					opacity: 0;

					&.graph-1 {
						transform: translate3d(0px, 0px, 0px);
					}
					&.graph-2 {
						transform: translate3d(-115px, 0px, 0px);
					}
					&.graph-3 {
						transform: translate3d(115px, 0px, 0px);
					}
				}

				.cover-text {
					position: absolute;
					top: 0%;
					left: 0%;
					width: 50%;
					height: 100%;

					background-color: #ffffff;


					.container {
						position: absolute;
						top: 50%;
						width: 100%;
						height: auto;
						transform: translate3d(0px, -50%, 0px);

						.description {
							width: 100%;
							float: none;

							p {
								width: 55%;
								min-width: 300px;
								margin: 0 auto;
								text-align: center;

								font-family: 'HPSimplified-Light';
								font-size: 16px;
								color: #000000;
								line-height: 26px;
							}
						}
					}

					.link {
						position: absolute;
						width: auto;
						color: #000000;
						cursor: pointer;
						height: auto;
						float: left;
						display: none;

						.download {
							position: relative;
							float: left;
							width: 80%;
							margin-bottom: 10px;

							p {
								font-family: 'HPSimplified-Regular';
								font-size: 12px;
								color: #000000;
								text-decoration: underline;
								letter-spacing: 6px;

								span {
									display: block;
								}
							}
						}

						.stores {
							position: relative;
							width: 100%;
							float: left;
			
							a {
								float: left;
								margin-right: 10px;

								img {
									height: 35px;
								}
							}
						}
					}
				}

				.cover-image {
					.container {
						position: absolute;
						top: 0px;
						left: 50%;
						width: 50%;
						height: 100%;
						overflow: hidden;
						background-color: #000000;

						.background {
							position: absolute;
							top: 0px;
							left: -100%;
							width: 200%;
							height: 100%;

							background-image: url('../assets/images/website/home/technology/background_1.jpg');
							background-size: cover;
							background-position: center center;
						}
					}
				}
			}

			.google {
				position: absolute;
				bottom: 10%;
				right: 11%;
				width: 130px;
				pointer-events: none;
				margin-bottom: 25px;
				margin-right: 3px;
				display: none;

				img {
					float: left;
					width: 100%;
					opacity: 0;
				}
			}

			.text {
				position: absolute;
				top: 50%;
				right: 11%;
				transform: translate3d(0px, -50%, 0px);
				overflow: hidden;

				.highlight {
					width: auto;
					float: right;
					height: 30px;

					p {
						font-family: 'HPSimplified-Regular';
						font-size: 14px;
						color: #1a8ed4;
						text-transform: uppercase;
						letter-spacing: 9px;
					}

					.text-underline {

						.underline {
							margin-top: 0px;
						}
					}
				}

				.title {
					margin-top: 10px;
					width: 100%;
					float: right;
					height: 150px;

					p {
						font-family: 'HPSimplified-Light';
						font-size: 77px;
						color: #000000;
						text-transform: uppercase;
						letter-spacing: 3px;
						line-height: 75px;
						text-align: right;
						width: auto;

						span {
							display: block;
						}
					}
				}

				.line-horizontal {
					margin: 0;
					margin-top: 20px;
					width: 240px;
					height: 10px;
					float: right;

					&.en {
						width: 380px;
					}
				}

				.details {
					position: relative;
					width: 100%;
					color: #000000;
					float: right;
					text-align: right;
					margin-top: 40px;
					height: 150px;

					p {
						font-family: 'HPSimplified-Light';
						font-size: 16px;
						line-height: 28px;
						letter-spacing: 1px;
						width: 300px;
						float: right;

						span {
							display: block;
						}
					}
				}

				.link {
					position: relative;
					width: auto;
					color: #000000;
					margin-top: 40px;
					cursor: pointer;
					height: 40px;
					float: right;
					margin-bottom: 30px;
					margin-right: 6px;
					pointer-events: auto;

					.icon {
						position: relative;
						top: 0px;
						left: 0px;
						width: 40px;
						height: 40px;
						background-image: url('../assets/images/website/home/technology/arrow_right.png');
						background-size: 100% 100%;
						background-repeat: no-repeat;
						float: right;
						display: none;
					}

					.text {
						position: relative;
						width: auto;
						height: 40px;
						top: 0px;
						left: 0px;
						right: 0px;
						float: right;
						transform: translate3d(0px, 0px, 0px);
						overflow: visible;

						p {
							font-family: 'HPSimplified-Regular';
							font-size: 15px;
							color: #000000;
							letter-spacing: 0px;
							float: left;
							line-height: 40px;
							padding-left: 40px;
							padding-right: 40px;
							padding-top: 3px;
							padding-bottom: 3px;
							border-radius: 3px;
							border: 1px solid #000000;
						}
					}
				}

				.download {
					margin-top: 50px;
					margin-bottom: 20px;
					width: 100%;
					display: block;
					float: right;
					// display: none;

					p {
						float: right;
						font-family: 'HPSimplified-Regular';
						font-size: 12px;
						color: #000000;
						text-decoration: underline;
						letter-spacing: 10px;

						span {
							display: block;
							text-align: right;
						}
					}
				}

				.stores {
					width: auto;
					float: right;
					pointer-events: auto;
					a {
						pointer-events: auto;
						float: right;
						opacity: 1;
						margin-left: 10px;
						// &:first-child{
						// 	display: none;
						// }
					}
				}
			}
		}

		.step-left {
			// display: none;
			.background {
				position: absolute;
				top: 0px;
				left: 0px;
				width: 100%;
				height: 100%;
				pointer-events: none;
				overflow: hidden;

				.graph {
					position: absolute;
					bottom: 10%;
					left: 25%;
					height: 98px;
					width: 1px;
					background-color: #ffffff;
					opacity: 0.2;

					&.graph-1 {
						transform: translate3d(0px, 0px, 0px);
					}
					&.graph-2 {
						transform: translate3d(-115px, 0px, 0px);
					}
					&.graph-3 {
						transform: translate3d(115px, 0px, 0px);
					}
				}

				.cover-text {
					position: absolute;
					top: 0%;
					left: 0%;
					width: 50%;
					height: 100%;

					background-color: #ffffff;


					.container {
						position: absolute;
						top: 50%;
						width: 100%;
						height: auto;
						transform: translate3d(0px, -50%, 0px);

						.description {
							width: 100%;
							float: none;

							p {
								width: 55%;
								min-width: 300px;
								margin: 0 auto;
								text-align: center;

								font-family: 'HPSimplified-Light';
								font-size: 16px;
								color: #000000;
								line-height: 26px;
							}
						}
					}

					.link {
						position: absolute;
						width: auto;
						color: #000000;
						margin-top: 40px;
						cursor: pointer;
						height: 40px;
						float: left;

						.icon {
							position: relative;
							top: 0px;
							left: 0px;
							width: 40px;
							height: 40px;
							background-image: url('../assets/images/website/home/technology/arrow_right.png');
							background-size: 100% 100%;
							background-repeat: no-repeat;
							float: left;
							display: none;
						}

						.text {
							position: relative;
							width: auto;
							height: 40px;
							top: 0px;
							left: 0px;
							right: 0px;
							float: left;
							transform: translate3d(0px, 0px, 0px);

							p {
								font-family: 'HPSimplified-Regular';
								font-size: 16px;
								color: #000000;
								letter-spacing: 0px;
								float: left;
								line-height: 40px;
								padding-left: 40px;
								padding-right: 40px;
								padding-top: 3px;
								padding-bottom: 3px;
								border-radius: 3px;
								border: 1px solid #000000;
							}
						}
					}

					.legal {
						position: absolute;
						width: 100%;
						display: none;

						p {
							font-family: 'HPSimplified-Light';
							font-size: 8px;
							color: #000000;
							line-height: 10px;
							text-transform: uppercase;

							span {
								display: block;
								color: #000000;
							}

							a {
								pointer-events: auto;
								text-decoration: none;
								color: #1a8ed4;
							}
						}
					}
				}

				.cover-image {
					.container {
						position: absolute;
						top: 0px;
						left: 50%;
						width: 50%;
						height: 100%;
						overflow: hidden;
						pointer-events: none;
						background-color: #000000;

						.background {
							position: absolute;
							top: 0px;
							left: -100%;
							width: 200%;
							height: 100%;

							background-image: url('../assets/images/website/home/technology/background_1.jpg');
							background-size: cover;
							background-position: center center;
						}
					}
				}
			}

			.text {
				position: absolute;
				top: 50%;
				left: 11%;
				transform: translate3d(0px, -50%, 0px);
				overflow: hidden;
				z-index: 10;

				.highlight {
					width: auto;

					p {
						font-family: 'HPSimplified-Regular';
						font-size: 14px;
						color: #1a8ed4;
						text-transform: uppercase;
						letter-spacing: 9px;
					}

					.text-underline {

						.underline {
							margin-top: 0px;
						}
					}
				}

				.title {
					margin-top: 45px;
					width: 100%;
					height: 225px;

					p {
						font-family: 'HPSimplified-Light';
						font-size: 77px;
						color: #000000;
						text-transform: uppercase;
						letter-spacing: 3px;
						line-height: 75px;
						text-align: left;
						width: auto;

						span {
							display: block;
						}
					}
				}

				.line-horizontal {
					margin: 0;
					margin-top: 20px;
					width: 90px;
					height: 10px;

					&.en {
						width: 210px;
					}
				}

				.details {
					position: relative;
					width: 100%;
					color: #000000;
					margin-top: 40px;
					height: 280px;

					p {
						font-family: 'HPSimplified-Light';
						font-size: 16px;
						line-height: 28px;
						letter-spacing: 1px;
						width: 350px;

						span {
							display: block;
						}
					}
				}

				.link {
					position: relative;
					width: auto;
					color: #000000;
					margin-top: 40px;
					cursor: pointer;
					height: 40px;
					width: 100%;
					float: left;
					margin-top: 30px;
					overflow: visible;
					margin-bottom: 80px;

					.icon {
						position: relative;
						top: 0px;
						left: 0px;
						width: 40px;
						height: 40px;
						background-image: url('../assets/images/website/home/technology/arrow_right.png');
						background-size: 100% 100%;
						background-repeat: no-repeat;
						float: left;
						display: none;
					}

					.text {
						position: relative;
						width: auto;
						height: 40px;
						top: 0px;
						left: 0px;
						right: 0px;
						transform: translate3d(0px, 0px, 0px);
						overflow: visible;

						p {
							font-family: 'HPSimplified-Regular';
							font-size: 15px;
							color: #000000;
							letter-spacing: 0px;
							float: left;
							line-height: 40px;
							border: 1px solid #000000;
							padding-left: 40px;
							padding-right: 40px;
							padding-top: 3px;
							padding-bottom: 3px;
							border-radius: 3px;
						}
					}
				}

				.download {
					float: left;
					margin-top: 50px;
					margin-bottom: 20px;
					width: 100%;
					display: none;

					p {
						font-family: 'HPSimplified-Regular';
						font-size: 12px;
						color: #000000;
						text-decoration: underline;
						letter-spacing: 10px;

						span {
							display: block;
						}
					}
				}

				.stores {
					width: 100%;
					float: left;
					display: none;

					a {
						opacity: 1;
					}
				}
			}

			.legal {
				position: absolute;
				bottom: 5%;
				left: 11%;
				width: 50%;
				height: auto;

				p {
					font-family: 'HPSimplified-Light';
					font-size: 10px;
					color: #000000;
					line-height: 18px;
					text-transform: uppercase;

					span {
						display: block;
					}

					a {
						text-decoration: none;
						color: #1a8ed4;
					}
				}
			}
		}
	}

	section.writers {
		position: absolute;
		background-color: #ffffff;
		color: #000000;
		height: 100%;
		transform: translate3d(0, 100%, 0);
		overflow: hidden;
		pointer-events: none;

		.step {
			position: relative;
			width: 100%;
			height: 100%;
			transform: translate3d(0, 0, 0);

			&.step-2, &.step-3, &.step-4, &.step-5, &.step-6, &.step-7, &.step-8, &.step-9, &.step-10 {
				position: absolute;
				top: 0px;
				left: 0px;
				width: 100%;
				height: 100%;

				transform: translate3d(0, 100%, 0);
				background-color: transparent;
			}

			&:last-child {

			}
		}

		.step-background {
			position: absolute;
			top: 0px;
			left: 0px;
			width: 100%;
			height: 100%;
			background-color: #000000;
			transform: translate3d(0, 100%, 0);
		}

		.line {
			position: absolute;
			top: 50%;
			left: 50%;
			width: 1px;
			height: 50px;
			transform: translate3d(-50%, -50%, 0);

			&.white {
				background-color: #ffffff;
			}
			&.black {
				background-color: #000000;
			}
			&.blue {
				background-color: #1a8ed4;
			}
		}

		.pictures {
			position: absolute;
			width: 100%;
			height: 100%;
			pointer-events: none;

			.picture {
				position: absolute;
				width: 100%;
				height: 100%;
				background-size: cover;
				background-position: center center;
				cursor: pointer;
				background-color: #000000;
				pointer-events: auto;

				.line {
					top: 25%;
				}

				.writers-data {
					position: absolute;
					top: 0px;
					left: 0px;
					width: 100%;
					height: 100%;
					transform: translate3d(0%, 0%, 0);
					opacity: 0;

					.writers-background {
						position: absolute;
						top: 0px;
						left: 0px;
						width: 100%;
						height: 100%;
						background-color: #000000;
						opacity: 0.5;
					}

					.writers-container {
						position: absolute;
						top: 50%;
						left: 0px;
						width: 100%;
						height: 140px;
						transform: translate3d(0, -50%, 0);

						.charpter {
							width: 100%;
							text-align: center;
							margin-bottom: 20px;
							opacity: 0;
							.charpter-text{
								display: inline-block;
								position: relative;
								overflow: hidden;
								height: 15px;
								span {
									font-family: 'HPSimplified-Light';
									font-size: 13px;
									color: #ffffff;
									line-height: 13px;
									text-transform: uppercase;
									letter-spacing: 7px;
									font-weight: bold;
								}
								.underline-1,
								.underline-2{
									position: absolute;
									width: 100%;
									height: 1px;
									background-color: #fff;
									transform: translate3d(-100%,0,0);
									bottom: 0px;
								}
							}

						}

						.name {
							width: 100%;
							text-align: center;

							span {
								color: #ffffff;
								line-height: 37px;
								font-family: 'HPSimplified-Light';
								font-size: 32px;
								display: block;
								text-transform: uppercase;
								opacity: 0;
							}

							margin-bottom: 20px;
						}

						.bar {
							overflow: hidden;
							width: 40px;
							height: 10px;
							margin: 0 auto;
							transform: translate3d(0, 0, 0);
							.graph {
								width: 42px;
								height: 10px;
								background-color: #ffffff;
								transform: translate3d('-100%', 0, 0);
							}
						}
					}
				}

				&.picture-1 {
					width: 50%;
					height: 50%;
					top: 0%;
					left: 0%;
					background-image: url('../assets/images/website/home/writers/thumbs/default/picture_1.jpg');
				}
				&.picture-2 {
					width: 25%;
					height: 25%;
					top: 0%;
					left: 50%;
					background-image: url('../assets/images/website/home/writers/thumbs/default/picture_2.jpg');
				}
				&.picture-3 {
					width: 25%;
					height: 25%;
					top: 25%;
					left: 50%;
					background-image: url('../assets/images/website/home/writers/thumbs/default/picture_3.jpg');
				}
				&.picture-4 {
					width: 25%;
					height: 50%;
					top: 0%;
					left: 75%;
					background-image: url('../assets/images/website/home/writers/thumbs/default/picture_4.jpg');
				}
				&.picture-5 {
					width: 25%;
					height: 25%;
					top: 50%;
					left: 0%;
					background-image: url('../assets/images/website/home/writers/thumbs/default/picture_5.jpg');
				}
				&.picture-6 {
					width: 25%;
					height: 25%;
					top: 75%;
					left: 0%;
					background-image: url('../assets/images/website/home/writers/thumbs/default/picture_6.jpg');
				}
				&.picture-7 {
					width: 50%;
					height: 50%;
					top: 50%;
					left: 25%;
					background-image: url('../assets/images/website/home/writers/thumbs/default/picture_7.jpg');
				}
				&.picture-8 {
					width: 25%;
					height: 25%;
					top: 50%;
					left: 75%;
					background-image: url('../assets/images/website/home/writers/thumbs/default/picture_8.jpg');
				}
				&.picture-9 {
					width: 25%;
					height: 25%;
					top: 75%;
					left: 75%;
					background-image: url('../assets/images/website/home/writers/thumbs/default/picture_9.jpg');
				}
				&.picture-10 {
					width: 25%;
					height: 50%;
					top: 0%;
					left: 0%;
					background-image: url('../assets/images/website/home/writers/thumbs/default/picture_10.jpg');
				}
				&.picture-11 {
					width: 25%;
					height: 25%;
					top: 0%;
					left: 25%;
					background-image: url('../assets/images/website/home/writers/thumbs/default/picture_11.jpg');
				}
				&.picture-12 {
					width: 25%;
					height: 25%;
					top: 25%;
					left: 25%;
					background-image: url('../assets/images/website/home/writers/thumbs/default/picture_12.jpg');
				}
				&.picture-13 {
					width: 25%;
					height: 25%;
					top: 0%;
					left: 50%;
					background-image: url('../assets/images/website/home/writers/thumbs/default/picture_13.jpg');
				}
				&.picture-14 {
					width: 25%;
					height: 25%;
					top: 25%;
					left: 50%;
					background-image: url('../assets/images/website/home/writers/thumbs/default/picture_14.jpg');
				}
				&.picture-15 {
					width: 25%;
					height: 50%;
					top: 0%;
					left: 75%;
					background-image: url('../assets/images/website/home/writers/thumbs/default/picture_15.jpg');
				}
				&.picture-16 {
					width: 25%;
					height: 50%;
					top: 50%;
					left: 0%;
					background-image: url('../assets/images/website/home/writers/thumbs/default/picture_16.jpg');
				}
				&.picture-17 {
					width: 25%;
					height: 50%;
					top: 50%;
					left: 25%;
					background-image: url('../assets/images/website/home/writers/thumbs/default/picture_17.jpg');
				}
				&.picture-18 {
					width: 25%;
					height: 25%;
					top: 50%;
					left: 50%;
					background-image: url('../assets/images/website/home/writers/thumbs/default/picture_18.jpg');
				}
				&.picture-19 {
					width: 25%;
					height: 25%;
					top: 75%;
					left: 50%;
					background-image: url('../assets/images/website/home/writers/thumbs/default/picture_19.jpg');
				}
				&.picture-20 {
					width: 25%;
					height: 25%;
					top: 50%;
					left: 75%;
					background-image: url('../assets/images/website/home/writers/thumbs/default/picture_20.jpg');
				}
				&.picture-21 {
					width: 25%;
					height: 25%;
					top: 75%;
					left: 75%;
					background-image: url('../assets/images/website/home/writers/thumbs/default/picture_21.jpg');
				}
				&.picture-22 {
					width: 25%;
					height: 50%;
					top: 0%;
					left: 0%;
					background-image: url('../assets/images/website/home/writers/thumbs/default/picture_22.jpg');
				}
				&.picture-23 {
					width: 25%;
					height: 25%;
					top: 0%;
					left: 25%;
					background-image: url('../assets/images/website/home/writers/thumbs/default/picture_23.jpg');
				}
				&.picture-24 {
					width: 25%;
					height: 25%;
					top: 25%;
					left: 25%;
					background-image: url('../assets/images/website/home/writers/thumbs/default/picture_24.jpg');
				}
				&.picture-25 {
					width: 50%;
					height: 50%;
					top: 0%;
					left: 50%;
					background-image: url('../assets/images/website/home/writers/thumbs/default/picture_25.jpg');
				}
				&.picture-26 {
					width: 25%;
					height: 25%;
					top: 50%;
					left: 0%;
					background-image: url('../assets/images/website/home/writers/thumbs/default/picture_26.jpg');
				}
				&.picture-27 {
					width: 25%;
					height: 25%;
					top: 75%;
					left: 0%;
					background-image: url('../assets/images/website/home/writers/thumbs/default/picture_27.jpg');
				}
				&.picture-28 {
					width: 50%;
					height: 50%;
					top: 50%;
					left: 25%;
					background-image: url('../assets/images/website/home/writers/thumbs/default/picture_28.jpg');
				}
				&.picture-29 {
					width: 25%;
					height: 25%;
					top: 50%;
					left: 75%;
					background-image: url('../assets/images/website/home/writers/thumbs/default/picture_29.jpg');
				}
				&.picture-30 {
					width: 25%;
					height: 25%;
					top: 75%;
					left: 75%;
					background-image: url('../assets/images/website/home/writers/thumbs/default/picture_30.jpg');
				}
			}
		}

		.graphs {
			position: absolute;
			width: 100%;
			height: 100%;
			pointer-events: none;

			.graph {
				position: absolute;
				width: 100%;
				height: 100%;
				background-size: cover;
				background-position: center center;
				background-color: transparent;
				overflow: hidden;

				.graph-background {
					position: absolute;
					top: 0px;
					left: 0px;
					width: 100%;
					height: 1005;
					background-color: #1a8ed4;
				}

				&.graph-1 {
					position: absolute;
					top: 68.5%;
					left: 0%;
					width: 25%;
					height: 25%;
					background-color: transparent;

					.line-1 {
						transform: translate3d(0px, -100%, 0);
						overflow: hidden;
					}
					.line-2 {
						transform: translate3d(100px, -100%, 0);
						overflow: hidden;
					}
					.line-3 {
						transform: translate3d(200px, -100%, 0);
						overflow: hidden;
					}

				}

				&.graph-2 {
					top: 35.5%;
					left: 25%;
					width: 25%;
					height: 25%;
				}

				&.graph-3 {
					top: 75%;
					left: 75%;
					width: 25%;
					height: 25%;
				}

				&.graph-4 {
					top: 0%;
					left: 75%;
					width: 25%;
					height: 25%;
				}

				&.graph-5 {
					top: 55%;
					left: 50%;
					width: 100px;
					height: 10px;
					transform: translate3d(-50%, -50%, 0);
				}
			}
		}

		.text {
			position: absolute;
			top: 40%;
			left: 11%;
			transform: translate3d(0px, -50%, 0px);
			overflow: hidden;
			pointer-events: auto;

			.highlight {
				width: 300px;

				p {
					font-family: 'HPSimplified-Regular';
					font-size: 14px;
					color: #1a8ed4;
					text-transform: uppercase;
					letter-spacing: 9px;
				}

				.text-underline {

					.underline {
						margin-top: 0px;
					}
				}
			}

			.title {
				margin-top: 45px;
				height: 150px;

				p {
					font-family: 'HPSimplified-Light';
					font-size: 77px;
					color: #000000;
					text-transform: uppercase;
					letter-spacing: 3px;
					line-height: 75px;

					span {
						display: block;
					}
				}
			}

			.line-horizontal {
				margin: 0;
				margin-top: 20px;
				width: 130px;
				height: 10px;

				&.en {
					width: 130px;
				}
			}

			.details {
				position: relative;
				width: 100%;
				color: #000000;
				float: right;
				text-align: left;
				margin-top: 40px;
				height: 150px;

				p {
					font-family: 'HPSimplified-Light';
					font-size: 16px;
					line-height: 28px;
					letter-spacing: 1px;
					width: 400px;
					float: left;

					span {
						display: block;
					}
				}
			}
		}

		.link {
			position: relative;
			width: auto;
			color: #ffffff;
			margin-top: 74px;
			cursor: pointer;
			height: 40px;
			float: left;

			.icon {
				position: relative;
				top: 0px;
				left: 0px;
				width: 40px;
				height: 40px;
				background-image: url('../assets/images/website/home/writers/arrow.png');
				background-size: 100% 100%;
				background-repeat: no-repeat;
				float: left;
				margin-left: 18px;
				display: none;
			}

			.text {
				position: relative;
				width: auto;
				height: 40px;
				top: 0px;
				left: 0px;
				right: 0px;
				float: left;
				transform: translate3d(0px, 0px, 0px);
				overflow: visible;

				p {
					font-family: 'HPSimplified-Regular';
					font-size: 12px;
					color: #ffffff;
					letter-spacing: 0px;
					float: left;
					line-height: 30px;
					padding-left: 30px;
					padding-right: 40px;
					padding-top: 3px;
					padding-bottom: 3px;
					border-radius: 3px;
					border: 1px solid #ffffff;
				}
			}
		}
	}

	section.instagram {
		position: absolute;
		background-color: #000000;
		color: #000000;
		height: 100%;
		background-size: auto 100%;
		background-position: center top;
		transform: translate3d(0, 100%, 0);
		overflow: hidden;

		.step {
			position: absolute;
			top: 0px;
			left: 0px;
			width: 100%;
			height: 100%;
			transform: translate3d(0, 0, 0);

			.background {
				position: absolute;
				top: 0px;
				left: 0px;
				width: 100%;
				height: 100%;
				background-color: #000000;

				.image {
					position: absolute;
					top: 0px;
					left: 0px;
					width: 100%;
					height: 100%;
					background-image: url('../assets/images/website/home/instagram/background.jpg');
					background-size: cover;
					background-position: center center;
					transform: translate3d(0, 0, 0);
				}
			}

			&.step-2 {
				pointer-events: none;
				transform: translate3d(0, 100%, 0);
			}
		}

		.scroll {
			position: absolute;
			bottom: 10%;
			width: 100%;
			transform: translate3d(0%, 0%, 0);
			z-index: 10;

			.title {
				width: auto;
				margin-bottom: 25px;

				p {
					width: auto;
					font-size: 10px;
					font-family: 'HPSimplified-Regular';
					letter-spacing: 5px;
					text-transform: uppercase;
					text-align: center;
					color: #ffffff;
				}

			}

			.button {
				width: 69px;
				height: 69px;
				margin: 0 auto;
				cursor: pointer;

				canvas {
					position: absolute;
					top: 0px;
					left: 50%;
					width: 69px;
					height: 69px;
					transform: translate3d(-50%, 0, 0);
				}

				img {
					position: absolute;
					top: 0px;

				}
			}
		}

		.pictures {
			position: absolute;
			width: 100%;
			height: 100%;
			pointer-events: none;

			.picture {
				position: absolute;
				width: 15%;
				height: 25%;
				background-size: cover;
				background-position: center top;
				cursor: pointer;
				overflow: hidden;
				pointer-events: auto;
				transform: translate3d(0%, 0%, 0);

				.icon {
					position: absolute;
					top: 50%;
					left: 50%;
					width: 40px;
					height: 40px;
					transform: translate3d(-50%, -50%, 0);
					background-size: 100% 100%;

					.button_container {
						position: relative;
						top: 0px;
						left: 0px;
						width: 100%;
						height: 100%;
						transform-style: preserve-3d;
						perspective: 1000;

						img {
							width: 100%;
							backface-visibility: hidden;
						}

						.hover {
							position: absolute;
							top: 0px;
							left: 0px;
							opacity: 0;
						}
					}
				}

				.background {
					position: absolute;
					top: 0px;
					left: 0px;
					width: 100%;
					height: 100%;
					transform: translate3d(0, 0, 0);
					backface-visibility: hidden;
					background-size: cover;
					background-position: center center;

					.overlayer {
						position: absolute;
						top: 0px;
						left: 0px;
						width: 100%;
						height: 100%;
						background-color: rgba(0, 0, 0, 0.5);
						display: none;
					}
				}

				&.picture-1 {
					bottom: 0%;
					right: 0%;
				}

				&.picture-1 {
					bottom: 50%;
					right: 30%;
				}
				&.picture-2 {
					bottom: 50%;
					right: 15%;
				}
				&.picture-3 {
					bottom: 50%;
					right: 0%;
				}
				&.picture-4 {
					bottom: 25%;
					right: 45%;
				}
				&.picture-5 {
					bottom: 25%;
					right: 30%;
				}
				&.picture-6 {
					bottom: 25%;
					right: 15%;
				}
				&.picture-7 {
					bottom: 25%;
					right: 0%;
				}
				&.picture-8 {
					bottom: 0%;
					right: 60%;
				}
				&.picture-9 {
					bottom: 0%;
					right: 45%;
				}
				&.picture-10 {
					bottom: 0%;
					right: 30%;
				}
				&.picture-11 {
					bottom: 0%;
					right: 15%;
				}
				&.picture-12 {
					bottom: 0%;
					right: 0%;
				}


			}
		}

		.text {
			position: absolute;
			top: 40%;
			left: 11%;
			transform: translate3d(0px, -50%, 0px);
			z-index: 10;
			overflow: hidden;

			.highlight {
				width: 300px;

				p {
					font-family: 'HPSimplified-Regular';
					font-size: 14px;
					color: #1a8ed4;
					text-transform: uppercase;
					letter-spacing: 9px;
				}

				.text-underline {

					.underline {
						margin-top: 0px;
					}
				}
			}

			.title {
				margin-top: 45px;
				height: 150px;

				p {
					font-family: 'HPSimplified-Light';
					font-size: 77px;
					color: #ffffff;
					text-transform: uppercase;
					letter-spacing: 3px;
					line-height: 75px;

					span {
						display: block;
					}
				}
			}

			.line-horizontal {
				margin: 0;
				margin-top: 20px;
				width: 190px;
				height: 10px;

				&.en {
					width: 190px;
				}
			}

			.details {
				position: relative;
				width: 100%;
				color: #000000;
				float: right;
				text-align: left;
				margin-top: 40px;
				height: 150px;

				p {
					font-family: 'HPSimplified-Light';
					font-size: 16px;
					line-height: 28px;
					letter-spacing: 1px;
					width: 400px;
					float: left;
					color: #ffffff;

					span {
						display: block;
					}

					a {
						text-decoration: underline;
					}
				}
			}
		}
	}

	section.footer {
		position: absolute;
		background-color: #000000;
		color: #ffffff;
		height: 100%;
		background-size: auto 100%;
		background-position: center top;
		transform: translate3d(0, 100%, 0);
		overflow: hidden;

		.container {
			position: absolute;
			top: 0px;
			left: 0px;
			width: 100%;
			height: 100%;
			background-color: #ffffff;

			.content {
				position: absolute;
				top: 50%;
				left: 0px;
				width: 100%;
				height: 900px;
				transform: translate3d(0%, -50%, 0px);

				.brand {
					position: absolute;
					width: auto;
					top: 20%;
					left: 50%;
					width: 102px;
					margin-left: -51px;
					z-index: 1;

					.logo {
						width: 100%;
						text-align: center;
						img {
							margin-left: -20px;
						}
					}

					.title {
						width: auto;
						margin-top: 22px;

						.text-underline {

							p {
								font-size: 10px;
								font-family: 'HPSimplified-Regular';
								letter-spacing: 5px;
								text-transform: uppercase;
								text-align: center;
							}

							.underline {
								width: 95%;
								background-color: #1a8ed4;
							}

						}

					}
				}

				.magic-words {
					position: absolute;
					width: 100%;
					top: 30%;
					z-index: 0;

					.logo {
						width: 100%;
						text-align: center;
						z-index: 1;
						transform: translate3d(0%, 0%, 0);

						video {
							position: absolute;
							top: 0px;
							left: 0px;
							width: 680px;
							left: 50%;
							opacity: 1;
							margin-left: -340px;
							margin-top: 55px;
							z-index: 1;
						}
						img {
							opacity: 0;
						}
					}

					.description {
						font-size: 14px;
						font-family: 'HPSimplified-Regular';
						letter-spacing: 7px;
						text-transform: uppercase;
						text-align: center;
						font-weight: bold;
						margin-top: -70px;
						color: #696969;
						z-index: 10;
						transform: translate3d(0%, 0%, 0);
					}
				}

				.details {
					position: absolute;
					top: 530px;
					width: 100%;
					font-size: 16px;
					font-family: 'HPSimplified-Light';
					letter-spacing: 1px;
					text-align: center;
					color: #000000;
					line-height: 25px;

					p{
						width: 35%;
						margin: 0 auto;

						span {
							display: block;
						}
					}
				}

				.download {
					position: absolute;
					top: 650px;
					left: 50%;
					transform: translate3d(-50%, 0%, 0);

					span {
						font-family: 'HPSimplified-Regular';
						font-size: 15px;
						color: #000000;
						border: 1px solid red;
						padding: 40px;
						padding-top: 10px;
						padding-bottom: 10px;
						border: 1px solid #000000;
						border-radius: 2px;
					}
				}
			}

			.follow {
				position: absolute;
				bottom: 5%;
				height: 50px;
				width: 100%;
				text-align: center;

				.text {
					font-family: 'HPSimplified-Regular';
					font-size: 10px;
					text-transform: uppercase;
					opacity: 0.5;
					color: #1a8ed4;
					text-align: center;
					width: 100%;
					left: 0;
					margin-left: 0px;
					padding-left: 0px;
					letter-spacing: 4px;
				}

				.social {
					position: absolute;
					top: 20px;
					left: 50%;
					transform: translate3d(-50%, 0%, 0);

					.button {
						width: auto;
						float: left;
						cursor: pointer;
						&:first-child {
							margin-right: 10px;
						}

						.button_container {
							transform-style: preserve-3d;
							perspective: 1000;
							border: 2px solid #1a8ed4;
							height: 42px;
							width: 42px;
							border-radius: 50%;
							position: relative;
							box-sizing: border-box;
						}

						img {
							backface-visibility: hidden;
							position: absolute;
							top: 50%;
							left: 50%;
							transform: translate3d(-50%,-50%,0);
						}

						&:nth-child(1){
							.icon,
							.invert{
								width: auto;
								height: 49%;
							}
						}
						&:nth-child(2){
							.icon,
							.invert{
								width: auto;
								height: 38%;
							}
						}

						.icon {
							opacity: 1;
						}

						.invert{
							opacity: 0;
						}

						.hover {
							position: absolute;
							top: 0px;
							left: 0px;
							transform: rotateY(0deg);
							opacity: 0;
						}
					}
				}
			}
		}
	}

	section.modal-writers {
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;

		opacity: 1;
		visibility: hidden;
		transform: translate3d(100%, 0%, 0);

		.content {
			position: absolute;
			top: 0px;
			left: 0px;
			width: calc(100% - 720px);
			height: 100%;

			.thumb {
				position: absolute;
				top: 0px;
				left: 0px;
				width: 10%;
				height: 10%;
				transform: translate3d(0, 0, 0);
				cursor: pointer;
				overflow: hidden;
				background-color: #000000;

				.background {
					position: absolute;
					top: 0px;
					left: 0px;
					width: 100%;
					height: 100%;
					background-size: cover;
					background-position: center center;
					opacity: 0.6;
				}

				&.size-1 {
					width: 20%;
					height: 16.7666666666666669%;
				}

				&.size-2 {
					width: 20%;
					height: 16.7666666666666669%;
				}

				&.size-3 {
					width: 20%;
					height: 16.766667%;
				}

				&.thumb-1 {
					top: 0%;
					left: 0%;
				}
				&.thumb-2 {
					top: 0%;
					left: 20%;
				}
				&.thumb-3 {
					top: 0%;
					left: 40%;
				}
				&.thumb-4 {
					top: 0%;
					left: 60%;

					.background {
						background-position: center top;
					}
				}
				&.thumb-5 {
					top: 0%;
					left: 80%;
				}
				&.thumb-6 {
					top: 16.666666666666668%;
					left: 0%;
				}
				&.thumb-7 {
					top: 16.666666666666668%;
					left: 20%;
				}
				&.thumb-8 {
					top: 16.666666666666668%;
					left: 40%;
				}
				&.thumb-9 {
					top: 16.666666666666668%;
					left: 60%;
				}
				&.thumb-10 {
					top: 16.666666666666668%;
					left: 80%;
				}
				&.thumb-11 {
					top: 33.333333333333336%;
					left: 0%;
				}
				&.thumb-12 {
					top: 33.333333333333336%;
					left: 20%;
				}
				&.thumb-13 {
					top: 33.333333333333336%;
					left: 40%;
				}
				&.thumb-14 {
					top: 33.333333333333336%;
					left: 60%;
				}
				&.thumb-15 {
					top: 33.333333333333336%;
					left: 80%;
				}
				&.thumb-16 {
					top: 50%;
					left: 0%;
				}
				&.thumb-17 {
					top: 50%;
					left: 20%;
				}
				&.thumb-18 {
					top: 50%;
					left: 40%;
				}
				&.thumb-19 {
					top: 50%;
					left: 60%;
				}
				&.thumb-20 {
					top: 50%;
					left: 80%;
				}
				&.thumb-21 {
					top: 66.66666666666667%;
					left: 0%;
				}
				&.thumb-22 {
					top: 66.66666666666667%;
					left: 20%;

					.background {
						background-position: center top;
					}
				}
				&.thumb-23 {
					top: 66.66666666666667%;
					left: 40%;
				}
				&.thumb-24 {
					top: 66.66666666666667%;
					left: 60%;
				}
				&.thumb-25 {
					top: 66.66666666666667%;
					left: 80%;
				}
				&.thumb-26 {
					top: 83.33333333333334%;
					left: 0%;
				}
				&.thumb-27 {
					top: 83.33333333333334%;
					left: 20%;
				}
				&.thumb-28 {
					top: 83.33333333333334%;
					left: 40%;
				}
				&.thumb-29 {
					top: 83.33333333333334%;
					left: 60%;
				}
				&.thumb-30 {
					top: 83.33333333333334%;
					left: 80%;
				}

			}
		}

		.left {
			display: none;
			position: fixed;
			top: 10px;
			left: 0px;
			float: left;
			margin-left: 10px;

			.button {
				width: auto;
				float: left;
				cursor: pointer;

				img {
					height: 30px;
				}
			}
		}
	}

	section.details-writers {
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;

		opacity: 1;
		visibility: hidden;
		z-index: 9998;
		transform: translate3d(0%, 100%, 0);

		.icon-up {
			position: relative;
			margin: 60px auto;
			width:15px;

			img {
				width: 15px;
				height: auto;
			}
		}

		.content {
			position: absolute;
			top: 0px;
			left: 0px;
			width: 100%;
			height: 100%;
			transform: translate3d(0%, 0%, 0);

			.about-view {

				.image {
					position: absolute;
					top: 0%;
					left: 0%;
					width: 50%;
					height: 100%;
					overflow: hidden;
					background-color: #000000;
					transform: translate3d(0%, 0%, 0);

					.background {
						position: absolute;
						top: 0px;
						left: 0px;
						width: 100%;
						height: 100%;
						transform: translate3d(0%, 0%, 0);

						.thumb {
							position: absolute;
							top: 0px;
							left: 0px;
							width: 100%;
							height: 100%;
							background-color: #000000;
							transform: translate3d(0, 100%, 0);
							overflow: hidden;

							.picture {
								position: absolute;
								top: 0px;
								left: 0px;
								width: 100%;
								height: 100%;
								background-color: #000000;
								transform: translate3d(0%, 0%, 0);
								background-size: cover;
								background-position: center center;
								overflow: hidden;
								visibility: hidden;
							}
						}
					}
				}

				.details {
					position: absolute;
					top: 0%;
					left: 50%;
					width: 50%;
					height: 100%;

					background-color: #ffffff;
					overflow: hidden;


					.text {
						position: absolute;
						top: 50%;
						left: 0px;
						width: 100%;
						height: auto;
						transform: translate3d(0%, -50%, 0);

						.chapter {
							position: relative;
							width: 100%;

							p {
								font-family: 'HPSimplified-Regular';
								font-size: 14px;
								color: #1a8ed4;
								letter-spacing: 10px;
								text-decoration: underline;
								text-transform: uppercase;
								text-align: center;
								line-height: 100px;
								height: 100px;
							}
						}

						.name {
							position: relative;
							width: 100%;

							p{
								font-family: 'HPSimplified-Light';
								font-size: 79px;
								color: #000000;
								letter-spacing: 2px;
								text-transform: uppercase;
								text-align: center;
								line-height: 80px;

								span {
									display: block;
									height: 80px;
								}

							}
						}

						.info {
							position: relative;
							width: 100%;

							p {
								font-family: 'HPSimplified-Light';
								font-size: 16px;
								color: #000000;
								letter-spacing: 1px;
								text-align: center;
								font-weight: bold;
								line-height: 40px;
								font-weight: bold;
								height: 40px;
							}
						}

						.introduction {
							position: relative;
							width: 100%;
							padding-top: 82px;
							height: 120px;

							p {
								width: 60%;
								margin: 0 auto;
								font-family: 'HPSimplified-Light';
								font-size: 23px;
								color: #1a8ed4;
								letter-spacing: 1px;
								text-align: center;
								font-weight: bold;
								line-height: 30px;
							}
						}

						.link {
							position: relative;
							width: 212px;
							color: #000000;
							cursor: pointer;
							margin: 0 auto;
							margin-top: 120px;

							.icon {
								position: relative;
								top: 0px;
								left: 0px;
								width: 38px;
								height: 38px;
								background-image: url('../assets/images/website/home/writers/arrow_right.png');
								background-size: 100% 100%;
								background-repeat: no-repeat;
								float: left;
								display: none;
							}

							.text {
								top: 0px;
								position: relative;
								width: 300px;
								height: 40px;
								float: left;
								transform: translate3d(0px, 0px, 0px);

								p {
									font-family: 'HPSimplified-Regular';
									font-size: 15px;
									color: #000000;
									letter-spacing: 0px;
									float: left;
									line-height: 34px;
									padding-left: 30px;
									padding-right: 30px;
									padding-top: 5px;
									padding-bottom: 5px;
									border: 1px solid #000000;
									border-radius: 2px;
									width: 150px;
									text-align: center;
								}
							}
						}
					}
				}

				.button_video {
					position: absolute;
					bottom: 10%;
					left: 50%;
					transform: translate3d(-50%, 0%, 0);
					width: 242px;
					height: 242px;
					-webkit-border-radius: 50%;
					-moz-border-radius: 50%;
					-ms-border-radius: 50%;
					border-radius: 50%;
					overflow: hidden;
					cursor: pointer;
					background-color: #000000;
					// display: none;

					.background {
						position: absolute;
						top: 0px;
						left: 0px;
						width: 100%;
						height: 100%;
						background-size: cover;
						background-position: center center;

						.video {
							position: absolute;
							top: 0px;
							left: 0px;
							width: 100%;
							height: 100%;
							transform: translate3d(0%, 0%, 0%);
							opacity: 0;

							.player {
								position: absolute;
								top: 0px;
								left: 0px;
								width: 100%;
								height: 100%;
								transform: translate3d(0%, 0%, 0);
								visibility: hidden;
							}

							.cover {
								position: absolute;
								top: 0px;
								left: 0px;
								width: 100%;
								height: 100%;
								transform: translate3d(0%, 0%, 0);
								background-size: cover;
							}

						}
					}

					.button {
						position: absolute;
						top: 50%;
						left: 50%;
						width: 133px;
						height: 133px;
						transform: translate3d(-50%, -50%, 0);

						canvas {
							position: absolute;
							top: 0px;
							left: 0px;
							width: 133px;
							height: 133px;
						}

						img {
							height: 100%;
						}
					}
				}

				.timeline {
					position: absolute;
					top: 0px;
					left: 0px;
					width: 50%;
					height: 100%;
					transform: translate3d(0, 0, 0);
					// display: none;
					.background {
						position: absolute;
						top: 50%;
						left: 80px;
						width: 1px;
						height: calc(100% - 135px);
						background-color: #FFF;
						opacity: 1;
						transform: translate3d(0,-50%,0);
						opacity: .2;
					}

					.background-1 {
						position: absolute;
						top: 0px;
						left: 80px;
						width: 1px;
						height: 30px;
						background-color: #ffffff;
						opacity: 1;
						opacity: .2;
					}

					.background-2 {
						position: absolute;
						bottom: 0px;
						left: 80px;
						width: 1px;
						height: 30px;
						background-color: #ffffff;
						opacity: 1;
						opacity: .2;
					}

					&.hide_top_button{
						.background-1{
							height: 68px;
						}
						.arrow_up{
							opacity: 0;
							visibility: hidden;
						}
					}

					&.hide_down_button{
						.background-2{
							height: 68px;
						}
						.arrow_down{
							opacity: 0;
							visibility: hidden;
						}
					}

					.chapters {
						position: absolute;
						top: 50%;
						left: 70px;
						width: 100%;
						height: calc(100% - 170px);
						margin-left: -3px;
						overflow: hidden;
						-webkit-overflow-scrolling: touch;
						transform: translate3d(0%, -50%, 0);

						ul {
							position: absolute;
							top: 0px;
							left: 10px;
							width: 100%;
							height: auto;
							list-style: none;
							margin-top: 50px;
							transform: translate3d(0%, 0%, 0);

							li {
								position: relative;
								height: 50px;
								float: none;
								width: 250px;
								cursor: pointer;

								.bullet {
									position: absolute;
									top: 50%;
									left: 0px;
									width: 7px;
									height: 7px;
									background-color: #ffffff;
									border-radius: 100%;
									margin-top: -3px;
									opacity: .7;
								}

								.large-circle{
									width: 15px;
									height: 15px;
									background-color: #fff;
									border-radius: 50%;
									position: absolute;
									opacity: .4;
									left: -4px;
									top: 18px;
								}

								.icon {
									position: absolute;
									top: 50%;
									left: -8px;
									margin-top: -11px;
									width: 23px;
									height: 23px;
									background-image: url("../assets/images/website/home/writers/bullet.png");
									background-size: 100% 100%;
									opacity: 0;
								}

								.text {
									margin-left: 50px;
									opacity: 0;

									p {
										font-family: 'HPSimplified-Regular';
										font-size: 12px;
										line-height: 50px;
										color: #ffffff;
										text-transform: uppercase;
										letter-spacing: 8px;
										font-weight: bold;

										span {
											display: inline;
											text-decoration: underline;
										}
									}
								}

								&.active {
									.text {
										opacity: 1;
									}

									.icon {
										opacity: 1;
									}
								}
							}
						}
					}

					.arrow_up {
						position: absolute;
						top: 30px;
						left: 80px;
						margin-left: -19px;
						pointer-events: auto;
						cursor: pointer;

						img {
							height: 38px;
							transform: rotate(-180deg);
						}

						.hover {
							position: absolute;
							top: 0px;
							left: 0px;
							opacity: 0;
						}
					}

					.arrow_down {
						position: absolute;
						bottom: 30px;
						left: 80px;
						margin-left: -19px;
						pointer-events: auto;
						cursor: pointer;

						img {
							height: 38px;
						}

						.hover {
							position: absolute;
							top: 0px;
							left: 0px;
							opacity: 0;
						}
					}
				}
			}

			.video-view {
				position: absolute;
				top: 0px;
				left: 0px;
				width: 100%;
				height: 100%;
				overflow: hidden;

				.chromeless {
					position: absolute;
					top: 0%;
					left: 0%;
					width: 100%;
					height: 100%;
					background-color: #000000;

					.video {
						position: absolute;
						top: 0px;
						left: 0px;
						width: 100%;
						height: 100%;

						.player {
							position: relative;
							top: 0px;
							left: 0px;
							width: 100%;
							height: 100%;
						}

						.cover {
							position: absolute;
							top: 0px;
							left: 0px;
							width: 100%;
							height: 100%;
							background-size: cover;
							background-position: center center;
						}
					}

					.controls {
						position: absolute;
						top: 50%;
						left: 50%;
						width: 180px;
						height: auto;
						margin-left: -90px;
						margin-top: -66px;
						display: none;

						.button {
							width: 133px;
							height: 133px;
							cursor: pointer;
							margin: 0 auto;
							background-size: 100% 100%;

							canvas {
								position: absolute;
								top: 0px;
								left: 50%;
								width: 133px;
								height: 133px;
								z-index: 1;
								margin-left: -66.5px;
							}

							img {
								width: 100%;
							}
						}

						.title {
							width: 160px;
							text-align: center;
							margin: 0 auto;
							margin-top: 50px;

							p {
								width: 100%;
								font-size: 12px;
								font-family: 'HPSimplified-Regular';
								letter-spacing: 9px;
								text-transform: uppercase;
								text-align: left;
								font-weight: bold;
								color: #ffffff;
								text-decoration: underline;
							}

							span {
								width: auto;
								font-size: 14px;
								font-family: 'HPSimplified-Light';
								text-align: center;
								font-weight: bold;
								color: #ffffff;
							}

							.line-horizontal {
								width: 100%;
								margin-top: 0px;
								margin-bottom: 5px;
								background-color: #ffffff;
							}

						}
					}
				}
			}

			.pictures-view {
				position: absolute;
				top: 0px;
				left: 0px;
				width: 100%;
				height: 100%;
				overflow: hidden;

				.gallery {
					position: absolute;
					top: 0%;
					left: 0%;
					width: 100%;
					height: 100%;
					background-color: #000000;
					transform: translate3d(0%, 0%, 0);

					.image {
						position: absolute;
						top: 0px;
						left: 0px;
						width: 100%;
						height: 100%;
						overflow: hidden;
						transform: translate3d(0%, 0%, 0);

						.background {
							position: absolute;
							top: 0px;
							left: 0px;
							width: 100%;
							height: 100%;
							background-size: cover;
							background-position: center center;
							transform: translate3d(0%, 0%, 0);
						}
					}
				}

				.controls {
					position: absolute;
					bottom: 10%;
					left: 50%;
					width: 113px;
					height: auto;
					transform: translate3d(-50%, 0%, 0);

					.button {
						opacity: 0;
						width: auto;
						display: inline-block;
						cursor: pointer;
						margin-right: 30px;
						&:last-child{
							margin-right: 0;
						}

						.button_container {
							transform-style: preserve-3d;
							perspective: 1000;
							border: 2px solid #1a8ed4;
							height: 38px;
							width: 38px;
							border-radius: 50%;
							position: relative;
							box-sizing: border-box;
						}

						img {
							backface-visibility: hidden;
							position: absolute;
							top: 50%;
							left: 50%;
							transform: translate3d(-50%,-50%,0);
						}

						.icon,
						.invert{
							width: 50%;
						}

						.icon {
							opacity: 1;
						}

						.invert,
						.black{
							opacity: 0;
						}

						.hover {
							position: absolute;
							top: 0px;
							left: 0px;
							transform: rotateY(0deg);
							opacity: 0;
						}
					}
				}
			}

			.menu {
				position: absolute;
				top: 50%;
				right: 50px;
				width: auto;
				height: auto;
				transform: translate3d(0%, -50%, 0);
				// display: none;

				ul {
					list-style: none;
					width: 38px;

					li {
						position: relative;
						width: 38px;
						height: 38px;
						float: left;
						margin-bottom: 14px;
						cursor: pointer;

						&.button {
							opacity: 0;
							width: auto;
							float: left;
							cursor: pointer;

							.button_container {
								transform-style: preserve-3d;
								perspective: 1000;
								border: 2px solid #1a8ed4;
								height: 38px;
								width: 38px;
								border-radius: 50%;
								position: relative;
								box-sizing: border-box;
							}

							img {
								backface-visibility: hidden;
								position: absolute;
								top: 50%;
								left: 50%;
								transform: translate3d(-50%,-50%,0);
							}


							&:nth-child(3){
								.icon,
								.invert,
								.black {
									width: 48%;
								}
							}

							.icon {
								opacity: 1;
							}

							.invert,
							.black{
								opacity: 0;
							}

							.hover {
								position: absolute;
								top: 0px;
								left: 0px;
								transform: rotateY(0deg);
								opacity: 0;
							}
						}

						&:last-child {
							margin-bottom: 0px;
						}
					}
				}
			}
		}

		.right {
			position: fixed;
			top: 10px;
			right: 0px;
			margin-right: 50px;

			.button {
				opacity: 0;
				width: auto;
				float: left;
				cursor: pointer;

				.button_container {
					transform-style: preserve-3d;
					perspective: 1000;
					border: 2px solid #1a8ed4;
					height: 38px;
					width: 38px;
					border-radius: 50%;
					position: relative;
					box-sizing: border-box;
				}

				img {
					backface-visibility: hidden;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate3d(-50%,-50%,0);
				}
				.icon,
				.invert {
					width: 44%;
				}

				.icon {
					opacity: 1;
				}

				.invert,
				.black{
					opacity: 0;
				}

				.hover {
					position: absolute;
					top: 0px;
					left: 0px;
					transform: rotateY(0deg);
					opacity: 0;
				}
			}
		}
	}

	section.modal-video {
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;

		opacity: 1;
		visibility: hidden;
		z-index: 9999;
		transform: translate3d(0%, 0%, 0);
		background-color: #000000;
		overflow: hidden;

		.content {
			.chromeless {
				position: absolute;
				top: 0%;
				left: 0%;
				width: 100%;
				height: 100%;

				.controls {
					position: absolute;
					top: 50%;
					left: 50%;
					width: 180px;
					height: auto;
					margin-left: -90px;
					margin-top: -66px;
					display: none;

					.button {
						width: 133px;
						height: 133px;
						cursor: pointer;
						margin: 0 auto;
						background-size: 100% 100%;

						canvas {
							position: absolute;
							top: 0px;
							left: 50%;
							width: 133px;
							height: 133px;
							z-index: 1;
							margin-left: -66.5px;
						}

						img {
							width: 100%;
						}
					}
				}
			}
		}

		.right {
			position: fixed;
			top: 12px;
			right: 0px;
			margin-right: 50px;

			.button {
				width: auto;
				cursor: pointer;

				img {
					height: 38px;
				}
			}
		}
	}

	section.tool {
		visibility: hidden;
		transform: translate3d(0%, -100%, 0);
		overflow: hidden;

		.content {
			position: absolute;
			top: 0px;
			left: 0px;
			width: 100%;
			height: 100%;
			background-color: #ffffff;

			iframe {
				background-color: #ffffff;
			}
		}
	}

	section.fallback {
		position: absolute;
		background-color: #ffffff;
		opacity: 1;
		visibility: visible;
		transform: translate3d(0, 0, 0);
		display: none;
		z-index: 9999;

		.content {
			position: absolute;
			top: 50%;
			left: 50%;
			width: 400px;
			height: 180px;
			margin-left: -200px;
			margin-top: -90px;

			img {
				width: 100%;
				float: left;
				z-index:1;
			}

			p {
				position: absolute;
				top: 120px;
				font-family: 'HPSimplified-Regular';
				float: left;
				color: #000000;
				font-size: 9px;
				line-height: 9px;
				text-transform: uppercase;
				letter-spacing: 5.1px;
				text-align: left;
				z-index: 2;
				width: 500px;

				&.en {
					letter-spacing: 6.8px;
				}
			}
		}
	}

}
