.app {

	section.navigation {
		height: 45px;
		
		.menu {
			height: 30px;

			.left {
				margin-left: 30px;

				.logo {

					img {
						height: 30px;
					}
				}

				.title {
					padding-left: 10px;
					font-size: 12px;

					.text {
						line-height: 30px;
					}

					.cursor {
						width: 1px;
						height: 15px;
						margin-top: 7px;
					}
				}
			}
			.right{
				margin-right: 30px;
				.button {
					
					// .button_container {
					// 	height: 30px;
					// 	width: 30px;
					// }

					// img {
					// 	width: 70%;
					// }
					.button_container{
						width: 30px;
						height: 30px;
						.icon,
						.invert{
							width: 60%;
						}
					}
				}
			}
		}
	}

	section.share {
		height: 45px;
		margin-top: -65px;

		.menu{
			.right{
				margin-right: 30px;
				.button{
					&.facebook{
						margin-top: -38px;
					}
					&.twitter{
						margin-top: -76px;
					}
					.button_container{
						width: 30px;
						height: 30px;
						.icon,
						.invert{
							height: 50%;
						}
					}
				}
			}
		}
	}

	section.menu {
		width: 500px;

		.top {
			height: 45px;
			.container {
				height: 30px;

				.button{
					margin-right: 30px;
					.button_container{
						width: 30px;
						height: 30px;
					}
					.icon,
					.invert{
						width: 42%;
					}
				}
			}
		}

		.content {
			// transform: translate3d(-50%, -50%, 0);
			bottom: 90px;
			height: 80%;
			overflow: auto;
			.wrap-list {
				position: relative;
				list-style: none;

				.list-item {
					height: 40px;

					.title {
						position: relative;

						p {
							line-height: 40px;
							font-size: 11px;
							letter-spacing: 8px;
						}
					}

					&.active {
						.title {
							p {
								color: #017cce;
								text-decoration: underline;
							}
						}
					}

					&:nth-child(1){
						.bar {
							width: 80px;
						}
					}
					&:nth-child(2){
						.bar {
							width: 55px;
						}
					}
					&:nth-child(3){
						.bar {
							width: 100px;
						}
					}
					&:nth-child(4){
						.bar {
							width: 70px;
						}
					}
					&:nth-child(5){
						.bar {
							width: 90px;
						}
					}
					&:nth-child(7){
						.bar {
							width: 20px;
						}
					}
				}

				li.subitem {
					.item {
						height: 30px;
						font-size: 9px;
						line-height: 30px;
						letter-spacing: 4px;

						.user {
							width: 200px;
						}
						.charpter {
							width: 130px;
						}
					}
				}
			}
		}

		.bottom {
			height: 80px;

			.container {
				height: 30px;
				width: 420px;

				.button {
					width: 150px;
					margin-right: 30px;
					line-height: 20.1px;
					
					.button_container{
						width: 20px;
						height: 20px;
  						border: 1px solid #1a8ed4;
  						box-shadow: 0px 0px 1px #1a8ed4;
  						margin-right: 4px;
					}

					.icon {
						// width: 20px;
						// height: 20px;
						// margin-right: 10px;
					}

					.text {
						// float: left;

						p {
							font-size: 14px;
						}
					}
				}

				.language {
					line-height: 20px;

					.idiom {
						position: relative;

						p {
							font-size: 12px;
						}
					}

					.divisor {
						height: 20px;
					}
				}
			}

		}
	}

	section.home {

		.line-horizontal {
			margin: 0 auto;
			width: 100px;
			height: 1px;
		}

		.hashtag {
			left: 40px;
			width: 5px;
		}

		.hashtag-full {
			left: 40px;
			width: 5px;
		}

		.bars {
			.bar {
				top: 0px;
				width: 20px;
			}
		}
	}

	section.welcome {

		.brand {
			width: 333px;
			margin-left: -166.5px;
			
			.logo {
				width: 100%;
				text-align: center;
				img {
					margin-left: 0px;
					height: 60px;
				}
			}

			.title {
				margin-top: 15px;

				.text-underline {

					.content {
						width: 333px;
					}

					p {
						font-size: 13px;
						letter-spacing: 4.2px;
					}

				}

			}

			&.en {
				.title {
					.text-underline {
						p {
							letter-spacing: 5.4px;
						}
					}
				}
			}
		}

		.magic-words {
			position: absolute;
			width: 100%;
			top: 35%;
			height: 180px;

			.logo {
				width: 100%;
				margin-bottom: 0px;

				video {
					position: absolute;
					top: 0px;
					left: 0px;
					width: 610px;
					left: 50%;
					opacity: 1;
					margin-left: -305px;
					margin-top: 40px;
				}

				img {
					width: 600px;
					margin: 0 auto;
					opacity: 0;
				}
			}

			.description {
				font-size: 11px;
				width: 610px;
				letter-spacing: 19.3px;

				&.safari {
					letter-spacing: 18.1px;
				}

				&.firefox {
					letter-spacing: 18.9px;
				}
			}

			&.en {
				.description {
					letter-spacing: 23.8px;
					font-size: 11px;

					&.safari {
						letter-spacing: 22.7px;
					}

					&.firefox {
						letter-spacing: 23.5px;
					}
				}
			}
		}
	}

	section.introduction {

		.video {
			
			.top {
				height: 70px;
			}
		}

		.google {
			bottom: 5%;
			left: 40px;
			width: 100px;
			margin-bottom: 30px;
		}

		.text {
			top: 40%;

			.title {
				letter-spacing: 5px;
				line-height: 60px;
				height: 130px;

				p {
					font-size: 55px;

					span {
						display: block;
						height: 60px;

						&:nth-child(1) {
							position: absolute;
							top: 0px;
							text-align: left;
							overflow: hidden;
						}
						&:nth-child(2) {
							position: absolute;
							top: 60px;
							overflow: hidden;
							text-align: left;
						}
					}
				}
			}

			.description {
				margin-top: 10px;
				line-height: 20px;
				letter-spacing: 1px;

				p {
					font-size: 13px;

					span {
						display: block;
					}
				}
			}
		}

		.scroll {
			bottom: 5%;

			.title {
				margin-bottom: 15px;
				width: 190px;

				p {
					font-size: 9px;
					letter-spacing: 4px;
				}

			}

			.button {
				width: 69px;
				height: 69px;
				margin: 0 auto;
				cursor: pointer;

				transform: scale(0.8);
			}
		}
	}

	section.documentary {

		.chromeless {
			.controls {
				pointer-events: auto;

				.button {
					width: 100px;
					height: 100px;

					canvas {
						width: 100px;
						height: 100px;
					}
				}

				.title {
					width: 150px;
					margin-top: 30px;

					p {
						font-size: 10px;
					}

					span {
						font-size: 12px;
					}

				}
			}
		}
		

		.text {
			left: 8%;

			.highlight {
				width: 200px;

				p {
					font-size: 10px;
					letter-spacing: 7px;
				}
			}

			.title {
				margin-top: 35px;
				height: 150px;

				p {
					font-size: 50px;
					letter-spacing: 2px;
					line-height: 50px;
				}
			}

			.line-horizontal {
				width: 145px;

				&.en {
					width: 128px;
				}
			}
		}
	}

	section.project {
		.background {
			.cover-text {

				.container {

					.title {
						margin-bottom: 20px;

						.text-underline {
							p {
								font-size: 11px;
								letter-spacing: 9px;
							}
						}
					}

					.description {
						width: 100%;
						float: none;

						p {
							width: 55%;
							font-size: 12px;
							line-height: 20px;
						}
					}

				}
			}

			.book {
				width: 303.5px;
				height: 363px;
			}
		}

		.text {
			left: 8%;

			.highlight {
				width: 200px;

				p {
					font-size: 10px;
					letter-spacing: 7px;
				}
			}

			.title {
				margin-top: 35px;
				height: 100px;

				p {
					font-size: 50px;
					letter-spacing: 2px;
					line-height: 50px;
				}
			}

			.line-horizontal {
				width: 60px;

				&.en {
					width: 82px;
				}
			}
		}

		.list {
			left: 8%;

			ul {
				list-style: none;

				li {
					height: 40px;
					width: 300px;

					.bullet {
						top: 1px;
						left: 1px;
						width: 5px;
						height: 5px;
					}

					.icon {
						top: -7px;
						left: -7px;
						width: 20px;
						height: 20px;
					}

					.line {
						height: 40px;
					}

					.title {
						font-size: 10px;
						letter-spacing: 6px;
					}
				}
			}
		}
	}

	section.technology {
		.step-right {
			.google {
				bottom: 5%;
				right: 8%;
				width: 100px;
				margin-bottom: 30px;
			}

			.text {
				right: 8%;

				.highlight {
					width: auto;

					p {
						font-size: 10px;
						letter-spacing: 7px;
					}
				}

				.title {
					margin-top: 5px;
					height: 100px;

					p {
						font-size: 50px;
						letter-spacing: 2px;
						line-height: 50px;
					}
				}

				.line-horizontal {
					width: 160px;

					&.en {
						width: 250px;
					}
				}

				.details {
					margin-top: 30px;
					height: 100px;

					p {
						font-size: 11px;
						line-height: 20px;
						width: 300px;
					}
				}

				.link {
					margin-top: 30px;
				    margin-bottom: 0px;
				    height: 40px;

					.icon {
						width: 30px;
						height: 30px;
					}

					.text {
						height: 30px;

						p {
							font-size: 11px;
    						line-height: 30px;
						}
					}
				}

				.download {
					margin-top: 30px;
					margin-bottom: 10px;

					p {
						font-size: 10px;
						letter-spacing: 8px;
					}
				}

				.stores {
					a {
						img {
							height: 30px;
						}
					}
				}
			}
		}

		.step-left {
			.text {
				left: 8%;

				.highlight {
					width: auto;

					p {
						font-size: 10px;
						letter-spacing: 7px;
					}
				}

				.title {
					margin-top: 35px;
					height: 150px;

					p {
						font-size: 50px;
						letter-spacing: 2px;
						line-height: 50px;
					}
				}

				.line-horizontal {
					width: 60px;

					&.en {
						width: 140px;
					}
				}

				.details {
					margin-top: 30px;
					height: 170px;

					p {
						font-size: 11px;
						line-height: 20px;
						width: 320px;
					}
				}

				.link {
					margin-top: 30px;
					margin-bottom: 0px;
					height: 40px;

					.icon {
						width: 30px;
						height: 30px;
					}

					.text {
						height: 30px;

						p {
							font-size: 11px;
							line-height: 30px;
						}
					}
				}

				.download {
					margin-top: 30px;
					margin-bottom: 10px;

					p {
						font-size: 10px;
						letter-spacing: 8px;
					}
				}

				.stores {
					a {
						img {
							height: 30px;
						}
					}
				}
			}

			.legal {
				left: 8%;
			}
		}
	}

	section.writers {
		.text {
			left: 8%;

			.highlight {
				width: 200px;

				p {
					font-size: 10px;
					letter-spacing: 7px;
				}
			}

			.title {
				margin-top: 35px;
				height: 100px;

				p {
					font-size: 50px;
					letter-spacing: 2px;
					line-height: 50px;
				}
			}

			.line-horizontal {
				width: 85px;

				&.en {
					width: 92px;
				}
			}

			.details {
				margin-top: 30px;

				p {
					font-size: 11px;
					line-height: 20px;
					width: 300px;
				}
			}
		}

		.pictures {
			position: absolute;
			width: 100%;
			height: 100%;
			pointer-events: none;

			.picture {
				.line {
					
				}
				.writers-data {

					.writers-container {
						height: 120px;

						.charpter {
							span {
								font-size: 11px;
								line-height: 11px;
								letter-spacing: 6px;
							}
						}

						.name {
							span {
								line-height: 30px;
								font-size: 25px;
							}

						}
					}
				}
			}
		}
	}

	section.instagram {
		.text {
			left: 8%;

			.highlight {
				width: 200px;

				p {
					font-size: 10px;
					letter-spacing: 7px;
				}
			}

			.title {
				margin-top: 35px;
				height: 100px;

				p {
					font-size: 50px;
					letter-spacing: 2px;
					line-height: 50px;
				}
			}

			.line-horizontal {
				width: 130px;

				&.en {
					width: 130px;
				}
			}

			.details {
				margin-top: 30px;

				p {
					font-size: 11px;
					line-height: 20px;
					width: 300px;
				}
			}
		}

		.pictures {
			position: absolute;
			width: 100%;
			height: 100%;

			.picture {

				.icon {
					width: 30px;
					height: 30px;
				}

				
			}
		}
	}

	section.footer {
		.container {
			height: 100%;
			.content {
				height: 800px;

				.brand {
			
					.logo {
						width: 100%;
						text-align: center;
						img {
							margin-left: -0px;
							height: 60px;
						}
					}

					.title {
						margin-top: 15px;

						.text-underline {

							p {
								font-size: 9px;
								letter-spacing: 4px;
							}

						}

					}
				}

				.magic-words {
					position: absolute;
					width: 100%;
					top: 25%;
					height: 180px;

					.logo {
						width: 100%;
						margin-bottom: 0px;

						img {
							width: 600px;
							margin: 0 auto;
						}
					}

					.description {
						font-size: 11px;
						letter-spacing: 8px;
						margin-top: -50px;
					}
				}

				.details {
					top: 450px;
					font-size: 12px;
					line-height: 16px;

					p {
						width: 60%;
						margin: 0 auto;
					}
				}

				.download {
					top: 550px;

					span {
						font-family: 'HPSimplified-Regular';
						font-size: 12px;
					}
				}

			}
			.follow {
				.text {
					font-size: 9px;
				}
				.social{
					.button{
						width: auto;
						float: left;
						cursor: pointer;
						.button_container{
							width: 30px;
							height: 30px;
						}
						&:nth-child(1){
							.icon,
							.invert{
								width: auto;
								height: 50%;
							}		
						}
						&:nth-child(2){
							.icon,
							.invert{
								width: auto;
								height: 38%;
							}		
						}
					}
				}

			}
	
		}
	}

	section.modal-writers {
		.content {
			width: calc(100% - 500px);
		}
	}

	section.details-writers {
		.content {
			.about-view {
				.details {
					.text {
						.chapter {
							p {
								font-size: 10px;
								letter-spacing: 7px;
								line-height: 80px;
								height: 80px;
							}
						}
						.name {
							p {
								font-size: 50px;
								letter-spacing: 3px;
								line-height: 50px;
								span {
									height: 50px;
								}
							}
						}
						.info {
							p {
								font-size: 11px;
								line-height: 30px;
								height: 30px;
							}
						}
						.introduction {
							padding-top: 30px;
							p {
								font-size: 15px;
								line-height: 18px;
							}
						}
						.link {
							width: 180px;
							height: 30px;
							margin-top: 80px;

							.icon {
								width: 30px;
								height: 30px;
							}

							.text {
								height: 30px;

								p {
									padding-top: 5px;
									padding-bottom: 5px;
									padding-left: 20px;
									padding-right: 20px;
									font-size: 13px;
									line-height: 30px;
									width: 139px;
								}
							}
						}
					}
				}
				.button_video {
					bottom: 5%;
					width: 160px;
					height: 160px;
					transform: translate3d(-50%, 0%, 0);
					left: 50%;

					.button {

						width: 80px;
						height: 80px;

						canvas {
							position: absolute;
							top: 0px;
							left: 0px;
							width: 80px;
							height: 80px;
						}
					}
				}

				.timeline {
					.background {
						height: calc(100% - 120px);
					}
					&.hide_top_button{
						.background-1{
							height: 60px;
						}
					}

					&.hide_down_button{
						.background-2{
							height: 60px;
						}
					}

					.chapters {
						ul {

							li {

								.bullet {
									left: 1px;
									width: 5px;
									height: 5px;
									margin-top: -2px;
								}

								.text {

									p {
										font-size: 11px;
										letter-spacing: 7px;
									}
								}
							}
						}
					}

					.arrow_up {
						top: 30px;
						margin-left: -15px;

						img {
							height: 30px;
						}
					}

					.arrow_down {
						bottom: 30px;
						margin-left: -15px;

						img {
							height: 30px;
						}
					}
				}
			}
			.video-view {
				.chromeless {
					.controls {
						.button {
							width: 100px;
							height: 100px;

							canvas {
								position: absolute;
								top: 0px;
								left: 50%;
								width: 100px;
								height: 100px;
								z-index: 1;
								margin-left: -50px;
							}

							img {
								width: 100%;
							}
						}
						.title {
							width: 150px;
							margin-top: 30px;

							p {
								font-size: 10px;
							}

							span {
								font-size: 12px;
							}

						}
					}
				}
			}
			.pictures-view {
				.controls {
					// width: 90px;
					bottom: 5%;

					.button {
						.button_container {
							height: 30px;
							width: 30px;
						}

						.icon,
						.invert{
							width: 44%;
						}
					}
				}
			}
			.menu {
				right: 30px;

				ul {
					width: 30px;

					li {
						&.button {
							.button_container{
								width: 30px;
								height: 30px;
							}
						}
						width: 30px;
						height: 30px;
						margin-bottom: 14px;
						&:nth-child(1){
							.icon,
							.invert{
								width: 57%;
							}
						}
						&:nth-child(2){
							.icon,
							.invert{
								width: 52%;
							}
						}
						&:nth-child(2){

						}
					}
				}
			}
		}
		.right {

			top: 8px;
			margin-right: 30px;
			.button {
				.button_container{
					width: 30px;
					height: 30px;
				}
			}
		}
	}

	section.modal-video {

		.content {
			.chromeless {
				position: absolute;
				top: 0%;
				left: 0%;
				width: 100%;
				height: 100%;

				.controls {
					position: absolute;
					top: 50%;
					left: 50%;
					width: 180px;
					height: auto;
					margin-left: -90px;
					margin-top: -66px;

					.button {
						width: 133px;
						height: 133px;
						cursor: pointer;
						margin: 0 auto;
						background-size: 100% 100%;

						canvas {
							position: absolute;
							top: 0px;
							left: 50%;
							width: 133px;
							height: 133px;
							z-index: 1;
							margin-left: -66.5px;
						}

						img {
							width: 100%;
						}
					}
				}
			}
		}

		.right {
			top: 8px;
			margin-right: 30px;
		}
	}

}