.tablet {
	display: block;
}

.app {

	.scroll-bar {
		position: absolute;
		top: 0px;
		right: 0px;
		width: 8px;
		height: 100%;

		.scrub {
			width: 5px;
			height: 40px;
			margin-left: 2px;
			border-radius: 20px;
		}
	}

	section.share {
		display: none;
	}

	section.menu {
		.bottom {
			.container {
				margin-left: 0px;
				width: 440px;

				.button {
					width: 160px;
				}
			}

		}
	}

	section.home {

		.hashtag {
			left: 50px;
		}		

		.hashtag-full {
			left: 50px;
		}		
	}

	section.welcome {
		.magic-words {
			top: 25%;

			.logo {
				video {
					display: none;
				}
				img {
					opacity: 1;
				}
			}

			.description {
				margin-top: -65px;
				letter-spacing: 18.2px;
			}

			&.en {
				.description {
					letter-spacing: 22.7px;
				}
			}
		}
	}

	section.introduction {

		.video {

			.top {
				height: 70px;
			}
		}

		.google {
			width: 90px;
			margin-bottom: 25px;
		}


		.text {
			top: 45%;

			.title {
				letter-spacing: 5px;
				line-height: 60px;

				p {
					font-size: 55px;

					span {
						display: block;
					}
				}
			}

			.description {
				margin-top: 10px;
				line-height: 20px;
				letter-spacing: 1px;

				p {
					font-size: 13px;

					span {
						display: block;
					}
				}
			}
		}

		.scroll {
			.title {
				width: 185px;
				margin-bottom: 10px;
			}
		}
	}

	section.documentary {

		.chromeless {
			position: absolute;
			top: 55%;
			left: 50%;
			width: 70%;
			height: 60%;
			background-color: #000000;
			transform: translate3d(-50%, -50%, 0px);

			.controls {
				pointer-events: none;

				.button {
					width: 100px;
					height: 100px;
				}

				.title {
					width: 150px;
					margin-top: 30px;

					p {
						font-size: 10px;
					}

					span {
						font-size: 12px;
					}

				}
			}
		}
		

		.text {
			left: 8%;

			.highlight {
				width: 200px;

				p {
					font-size: 10px;
					letter-spacing: 7px;
				}
			}

			.title {
				margin-top: 35px;

				p {
					font-size: 50px;
					letter-spacing: 2px;
					line-height: 50px;
				}
			}

			.line-horizontal {
				width: 140px;

				&.en {
					width: 120px;
				}
			}
		}
	}

	section.project {
		.background {
			.cover-text {

				.container {

					.title {
						margin-bottom: 20px;

						.text-underline {
							p {
								font-size: 11px;
								letter-spacing: 9px;
							}
						}
					}

					.description {
						width: 100%;
						float: none;

						p {
							width: 55%;
							font-size: 12px;
							line-height: 20px;
						}
					}

				}
			}

			.book {
				width: 303.5px;
				height: 363px;
			}
		}

		.text {
			left: 8%;

			.highlight {
				width: 200px;

				p {
					font-size: 10px;
					letter-spacing: 7px;
				}
			}

			.title {
				margin-top: 35px;
				height: 100px;

				p {
					font-size: 50px;
					letter-spacing: 2px;
					line-height: 50px;
				}
			}

			.line-horizontal {
				width: 58px;

				&.en {
					width: 85px;
				}
			}
		}

		.list {
			left: 8%;

			ul {
				list-style: none;

				li {
					height: 40px;
					width: 300px;

					.bullet {
						top: 1px;
						left: 1px;
						width: 5px;
						height: 5px;
					}

					.icon {
						top: -7px;
						left: -7px;
						width: 20px;
						height: 20px;
					}

					.line {
						height: 40px;
					}

					.title {
						font-size: 10px;
						letter-spacing: 6px;
					}
				}
			}
		}
	}

	section.technology {
		.step-right {
			.text {
				right: 8%;

				.highlight {
					width: auto;

					p {
						font-size: 10px;
						letter-spacing: 7px;
					}
				}

				.title {
					margin-top: 10px;

					p {
						font-size: 50px;
						letter-spacing: 2px;
						line-height: 50px;
					}
				}

				.line-horizontal {
					width: 165px;

					&.en {
						width: 250px;
					}
				}

				.details {
					margin-top: 30px;

					p {
						font-size: 11px;
						line-height: 20px;
						width: 300px;
					}
				}

				.link {
					margin-top: 30px;
					height: 40px;

					.icon {
						width: 30px;
						height: 30px;
					}

					.text {
						height: 30px;

						p {
							font-size: 11px;
							line-height: 30px;
						}
					}
				}

				.download {
					margin-top: 30px;
					margin-bottom: 10px;

					p {
						font-size: 10px;
						letter-spacing: 8px;
					}
				}

				.stores {
					a {
						img {
							height: 30px;
						}
					}
				}
			}
		}

		.step-left {
			.text {
				right: 8%;

				.highlight {
					width: auto;

					p {
						font-size: 10px;
						letter-spacing: 7px;
					}
				}

				.title {
					margin-top: 35px;

					p {
						font-size: 50px;
						letter-spacing: 2px;
						line-height: 50px;
					}
				}

				.line-horizontal {
					width: 60px;
					&.en {
						width: 140px;
					}
				}

				.details {
					margin-top: 30px;

					p {
						font-size: 11px;
						line-height: 20px;
						width: 310px;
					}
				}

				.link {
					margin-top: 30px;
					margin-bottom: 0px;
					height: 40px;

					.icon {
						width: 30px;
						height: 30px;
					}

					.text {
						height: 30px;

						p {
							font-size: 11px;
							line-height: 30px;
						}
					}
				}

				.download {
					margin-top: 30px;
					margin-bottom: 10px;

					p {
						font-size: 10px;
						letter-spacing: 8px;
					}
				}

				.stores {
					a {
						img {
							height: 30px;
						}
					}
				}
			}

			.legal {
				p {
					font-size: 9px;
				}
			}
		}
	}

	section.writers {
		.text {
			left: 8%;

			.highlight {
				width: 200px;

				p {
					font-size: 10px;
					letter-spacing: 7px;
				}
			}

			.bars {
				display: none;
			}

			.title {
				margin-top: 35px;
				height: 100px;

				p {
					font-size: 50px;
					letter-spacing: 2px;
					line-height: 50px;
				}
			}

			.line-horizontal {
				width: 85px;

				&.en {
					width: 90px;
				}
			}

			.details {
				margin-top: 30px;

				p {
					font-size: 11px;
					line-height: 20px;
					width: 300px;
				}
			}
		}
	}

	section.instagram {
		.text {
			left: 8%;

			.highlight {
				width: 200px;

				p {
					font-size: 10px;
					letter-spacing: 7px;
				}
			}

			.title {
				margin-top: 35px;
				height: 100px;

				p {
					font-size: 50px;
					letter-spacing: 2px;
					line-height: 50px;
				}
			}

			.line-horizontal {
				width: 130px;

				&.en {
					width: 130px;
				}
			}

			.details {
				margin-top: 30px;

				p {
					font-size: 11px;
					line-height: 20px;
					width: 300px;
				}
			}
		}
	}

	section.footer {
		.container {
			height: 100%;

			.content {
				height: 750px;

				.magic-words {
					// top: 30px;

					.description {
						font-size: 12px;
						letter-spacing: 5.5px;
					}

					.logo {

						img {
							opacity: 1;
						}
					}
				}

				.details {
					// top: 320px;
					font-size: 14px;

					p {
						width: 65%;
					}
				}

				.follow {
					// top: 420px;
				}

			}
	
		}
	}

	section.modal-writers {
		.content {
			width: calc(100% - 500px);
		}
	}
}